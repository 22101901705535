import { ref, watch } from '@vue/composition-api'
import {
  useTableComponent, useCommenSettings, useAlert,
} from '@/libs/mixins/index'
import store from '@/store'
import router from '@/router'

export const useCustomerSetting = () => {
  const {
    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  } = useCommenSettings()

  const statusOptions = [{
    label: '使用中', value: 'Active',
  }, {
    label: '離線', value: 'Inactive',
  }, {
    label: '關閉', value: 'Close',
  }]

  const kycStatusOptions = ['未驗證', '已驗證', '驗證中']

  const kycTypeOptions = [
    { label: '身份證正面', key: 'id_front' },
    { label: '身份證背面', key: 'id_back' },
    { label: '健保卡正面', key: 'nhi_front' },
    { label: '健保卡背面', key: 'nhi_back' },
  ]

  const ui = {
    kycState: {
      未驗證: 'secondary', 已驗證: 'success', 驗證中: 'warning',
    },
    statusType: {
      Active: 'dot-green-selected', Inactive: 'dot-yellow-selected', Close: 'dot-gray',
    },
  }

  const getMetaListData = (...arg) => store.dispatch('api/getMetaList', ...arg)

  return {
    ui,
    statusOptions,
    kycStatusOptions,
    kycTypeOptions,

    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
    getMetaListData,
  }
}

export const useCustomerList = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    refetchData,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  const {
    kycTypeOptions,
  } = useCustomerSetting()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '狀態', key: 'status', sortable: true, searchable: true, select: true,
    },
    {
      label: '會員名稱', key: 'name', sortable: true, searchable: true, select: true,
    },
    {
      label: '驗證狀態', key: 'verify', sortable: true, searchable: true, select: true,
    },
    {
      label: '創建日期', key: 'created_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const isBusy = ref(false)
  const searchStatus = ref(null)
  const searchKycStatus = ref(false)

  const blankCustomerData = {
    id: null,
    firstname: null,
    lastname: null,
    email: null,
    status: 'Inactive',
    kyc_status: '未驗證',
    created_at: null,
  }

  const setCustomerUpdate = (...arg) => store.dispatch('branch-customer/setCustomerUpdate', ...arg)
  const setCustomerImageDelete = (...arg) => store.dispatch('branch-customer/setCustomerImageDelete', ...arg)

  const getCustomerListData = (ctx, callback) => {
    const route = router.currentRoute
    const { serial } = route.params

    // 整理filters
    let resolveFilters = ''

    if (searchStatus.value !== null) {
      resolveFilters = `status: ${searchStatus.value ? 'Active' : 0}`
    }

    if (searchKycStatus.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};kyc_status: ${searchKycStatus.value}`
      } else resolveFilters = `kyc_status: ${searchKycStatus.value}`
    }

    store.dispatch('branch-customer/getCustomerList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: timeRange.value,
      filters: resolveFilters,
      _: Date.now(),
    })
      .then(response => {
        if (!response.data.success) {
          useAlertToast(false, response.data.message)
          callback([])
          totalNum.value = 0
          isBusy.value = false
          return
        }
        const { data, total } = response.data.data

        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankCustomerData, item),
            kyc_images: kycTypeOptions.reduce((acc, option) => {
              acc[option.key] = null
              return acc
            }, {}),
          }

          if (Array.isArray(item.kyc_images)) {
            item.kyc_images.forEach(el => {
              if (el && el.type) {
                const match = kycTypeOptions.find(option => option.key === el.type)
                if (match) {
                  resolve.kyc_images[el.type] = el.image
                }
              }
            })
          }

          return resolve
        })

        if (serial === 'prehost') {
          callback(resolveData)
          totalNum.value = total
          isBusy.value = false
        } else {
          callback([])
          totalNum.value = 0
          isBusy.value = false
        }
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    timeRange.value = null
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else refetchData()
  }

  watch([currentPage, perPage, searchQuery, searchStatus, timeRange, searchKycStatus], () => {
    refetchData()
  })

  return {
    isBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    searchStatus,
    timeRange,
    searchKycStatus,
    refetchData,
    refetchTable,

    getCustomerListData,
    setCustomerImageDelete,
    setCustomerUpdate,
    useAlertToast,
    useHttpCodeAlert,
  }
}
