<template>
  <div>
    <div>
      <b-card no-body>
        <b-card-header class="pb-50">
          <h5>篩選器</h5>
        </b-card-header>

        <b-card-body>
          <b-row>
            <b-col
              cols="12"
              lg="2"
              md="3"
              class="mb-md-0 mb-1"
            >
              <label>狀態</label>
              <v-select
                v-model="searchStatus"
                :options="statusOptions"
                class="w-100"
                :reduce="option => option.value"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>

                <template v-slot:option="option">
                  <div class="d-flex align-items-center">
                    <span
                      class="mr-50 border state-dot"
                      :class="ui.statusType[option.value]"
                    />
                    {{ option.label }}
                  </div>
                </template>

                <template #selected-option="option">
                  <div class="d-flex align-items-center">
                    <span
                      class="mr-50 border state-dot"
                      :class="ui.statusType[option.value]"
                    />
                    {{ option.label }}
                  </div>
                </template>
              </v-select>
            </b-col>

            <b-col
              cols="12"
              lg="2"
              md="3"
              class="mb-md-0 mb-1"
            >
              <label>驗證狀態</label>
              <v-select
                v-model="searchKycStatus"
                :options="kycStatusOptions"
                class="w-100"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>
              </v-select>
            </b-col>
          </b-row>

        </b-card-body>
      </b-card>

      <b-card no-body>
        <div class="mx-2 mt-2 mb-50">
          <b-row>
            <b-col
              cols="12"
              md="8"
              sm="6"
            >
              <h4 class="mb-0">
                會員列表
              </h4>
            </b-col>

            <b-col
              cols="12"
              md="4"
              sm="6"
            >
              <div class="text-nowrap d-flex justify-content-end">
                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="重新整理"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="refetchTable"
                >
                  <b-img
                    src="/admin/images/table/refresh.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="列表顯示"
                  class="d-flex align-items-center actions-link-btn ml-25"
                >
                  <b-dropdown
                    variant="link"
                    toggle-class="p-0"
                    no-caret
                    right
                  >
                    <template #button-content>
                      <b-img
                        src="/admin/images/table/setting.svg"
                        class="actions-link-btn-image"
                        rounded
                      />
                    </template>

                    <b-dropdown-form>
                      <b-form-group>
                        <label class="mb-50">
                          列表顯示
                        </label>

                        <b-form-checkbox
                          v-for="column in tableColumnsSearchable"
                          :key="column.key"
                          v-model="column.select"
                          name="table-column"
                          class="mb-1"
                        >
                          {{ column.label }}
                        </b-form-checkbox>
                      </b-form-group>
                    </b-dropdown-form>

                  </b-dropdown>
                </div>
              </div>
            </b-col>

          </b-row>
        </div>

        <!-- 顯示個數/搜尋框 -->
        <div class="mx-2 mb-2">
          <b-row>
            <!-- 顯示個數 -->
            <b-col
              cols="12"
              md="6"
              sm="4"
              class="d-flex align-items-center justify-content-start mb-1 mb-sm-0"
            >
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="table-perPage-select"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>
              </v-select>
            </b-col>

            <!-- 搜尋框 -->
            <b-col
              cols="12"
              md="6"
              sm="8"
            >
              <!-- 搜尋框 -->
              <div class="w-100 mr-1">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="searchQuery"
                    debounce="500"
                    class="d-inline-block"
                    placeholder="搜尋..."
                  />
                  <b-input-group-append
                    v-if="searchQuery"
                    is-text
                  >
                    <feather-icon
                      icon="XIcon"
                      class="cursor-pointer text-muted"
                      @click="searchQuery = null"
                    />
                  </b-input-group-append>
                </b-input-group>
              </div>
            </b-col>

          </b-row>
        </div>

        <!-- 列表 -->
        <b-table
          ref="refDataListTable"
          :items="getCustomerListData"
          :fields="tableColumnsFilter"
          responsive
          striped
          hover
          primary-key="id"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          show-empty
          details-td-class="p-0"
          :stacked="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          <!-- 忙碌中 -->
          <template #table-busy>
            <b-skeleton-table
              :rows="5"
              :columns="tableColumnsFilter.length"
              hide-header
              :table-props="{ bordered: true, striped: true }"
            />
          </template>

          <!-- 查無資料 -->
          <template #empty>
            <div class="text-center my-5 animate__animated animate__fadeIn">
              <b-img
                :src="$store.state.app.themeImages.notFoundImg"
                fluid
                width="480"
                alt="查無資料"
              />
            </div>
          </template>

          <!-- 欄位: 編號 -->
          <template #cell(id)="data">
            <div class="table-col">
              <span>{{ data.item.id }}</span>
            </div>
          </template>

          <!-- 欄位: 狀態 -->
          <template #cell(status)="data">
            <div class="table-col">
              <div
                v-if="data.item.status === 'Active'"
                v-b-tooltip.hover.focus.v-secondary
                title="使用中"
                class="state-dot dot-green"
              />
              <div
                v-else-if="data.item.status === 'Inactive'"
                v-b-tooltip.hover.focus.v-secondary
                title="離線"
                class="state-dot dot-yellow"
              />
              <div
                v-else
                v-b-tooltip.hover.focus.v-secondary
                title="關閉"
                class="state-dot dot-gray"
              />
            </div>
          </template>

          <!-- 欄位: 會員名稱 -->
          <template #cell(name)="data">
            <div class="d-flex align-items-center">
              <div class="ml-50 w-100">
                <div class="d-flex align-items-end">
                  <b-link
                    class="font-weight-bold d-block text-nowrap show-text"
                    @click="data.toggleDetails"
                  >
                    {{ data.item.lastname }} {{ data.item.firstname }}
                  </b-link>
                </div>
                <small class="text-muted">
                  <div class="email-text">{{ data.item.email }}</div>
                </small>
              </div>
            </div>
          </template>

          <!-- 欄位: 驗證狀態 -->
          <template #cell(verify)="data">
            <div class="text-nowrap d-flex">
              <div
                class="user-state-alert"
                @click="data.toggleDetails"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="身分驗證"
                  rounded
                  fluid
                  class="user-state-icon"
                  :src="require('@/assets/images/pages/customer/id-card.svg')"
                />
                <b-badge :variant="ui.kycState[data.item.kyc_status]">
                  {{ data.item.kyc_status }}
                </b-badge>
              </div>

            </div>
          </template>

          <!-- 欄位: 新增時間 -->
          <template #cell(created_at)="data">
            <div style="min-width: 85px;">
              <div v-if="data.item.created_at">
                <span
                  v-b-tooltip.hover.focus.v-secondary
                  :title="`${moment(data.item.created_at).format('HH:mm')}`"
                >
                  {{ updateOnline(data.item.created_at, refonlineTime) }}
                </span>
              </div>

              <div v-else>
                <span>---</span>
              </div>
            </div>
          </template>

          <!-- 欄位: 動作 -->
          <template #cell(actions)="data">
            <div
              style="min-width: 85px;"
              class="text-nowrap"
            >
              <b-button
                v-if="data.item.kyc_status === '驗證中'"
                variant="success"
                size="sm"
                class="mr-50"
                @click="activateUser(data.item)"
              >
                啟用
              </b-button>

              <b-button
                v-if="data.item.kyc_status !== '未驗證'"
                variant="danger"
                size="sm"
                @click="deactivateUser(data.item)"
              >
                關閉
              </b-button>
            </div>
          </template>

          <!-- 欄位: 詳細 -->
          <template #row-details="data">
            <div class="table-toggle-detail border-top p-1">
              <b-card
                no-body
                class="m-0 border animate__animated animate__fadeIn"
              >
                <b-card-body>
                  <b-row>
                    <b-col
                      v-for="type in kycTypeOptions"
                      :key="type.key"
                      cols="12"
                      lg="3"
                      sm="6"
                    >
                      <b-form-group :label="type.label">
                        <div
                          v-if="data.item.kyc_images[type.key]"
                          class="image-view"
                        >
                          <span
                            class="image-view-remove cursor-pointer"
                            @click="submitRemoveKycImage(data.item, type.key)"
                          >
                            <feather-icon
                              icon="XIcon"
                              class="remove-icon"
                            />
                          </span>

                          <div
                            class="kyc-img-area"
                            @click="showImgs(data.item.kyc_images[type.key])"
                          >
                            <VueLoadImage
                              :use-loading="true"
                              :use-failed="true"
                              :blank-height="'140px'"
                              :loading-width="30"
                              :loading-height="30"
                              :failed-width="50"
                            >
                              <b-img
                                slot="image"
                                :src="data.item.kyc_images[type.key]"
                                class="kyc-img-thumbnail"
                                :alt="type.label"
                              />
                            </VueLoadImage>
                          </div>
                        </div>

                        <div
                          v-else
                          class="kyc-img-area full-placeholder"
                          style="height: 100%; width: 100%;"
                        >
                          <p class="mb-0 text-muted">
                            尚未上傳
                          </p>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </div>
          </template>
        </b-table>

        <!-- 顯示頁數/分頁 -->
        <div class="mx-2 mb-2">
          <b-row>
            <!-- 顯示頁數 -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">第 {{ dataMeta.from }} - {{ dataMeta.to }} 筆，共 {{ dataMeta.of }} 筆</span>
            </b-col>

            <!-- 分頁 -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalNum"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>

      </b-card>
    </div>
  </div>
</template>
<script>
import { onUnmounted } from '@vue/composition-api'
import moment from 'moment'
import {
  BCard, BRow, BTable, BCol, BPagination, VBTooltip, BDropdown, BImg, BLink, BBadge, BButton,
  BDropdownForm, BFormInput, BInputGroupPrepend, BInputGroup, BFormGroup, BFormCheckbox, BSkeletonTable,
  BCardBody, BCardHeader, BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import language from 'flatpickr/dist/l10n/zh-tw'
import router from '@/router'
import store from '@/store'

import VueLoadImage from '@/layouts/components/ImageLoader/VueLoadImage.vue'
import { useCustomerList, useCustomerSetting } from '../useCustomer'
import { useSwalToast, useAlert } from '@/libs/mixins/index'
import useStoreModule from '../useStoreModule'

export default {
  metaInfo() {
    return {
      title: this.title ? this.title : store.state['app-config'].app.appName,
      titleTemplate: `%s - ${store.state.app.titleTemplate}`,
    }
  },
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BRow,
    BCol,
    BImg,
    BLink,
    BBadge,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownForm,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BFormCheckbox,
    BSkeletonTable,

    vSelect,
    VueLoadImage,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  data() {
    return {
      keyArray: [],
      selected: null,
      title: router.currentRoute.meta.pageTitle,
    }
  },
  computed: {
    tableColumnsFilter() {
      return this.tableColumns
        .filter(f => f.select)
    },
    tableColumnsSearchable() {
      return this.tableColumns
        .filter(f => f.searchable)
    },
  },
  watch: {
    '$route.params.serial': 'refetchTable',
  },
  mounted() {
    this.timer = setInterval(this.onlineTime, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    moment,
    // (觸發)圖片顯示
    showImgs(iamge) {
      this.$viewerApi({
        images: [iamge],
        options: {
          navbar: false,
          movable: false,
        },
      })
    },

    // (觸發)啟用會員
    activateUser(item) {
      this.useSwalAlertWarning('啟用驗證', `你確定要啟用 ${item.firstname}${item.lastname} 的實名驗證嗎?`)
        .then(result => {
          if (result.value) {
            this.setCustomerUpdate({
              user_id: item.id,
              data: {
                kyc_status: '已驗證',
                reset: false,
              },
            }).then(() => {
              this.refetchData()
              this.useSwalAlertCenter(true, '啟用成功!', '實名驗證已啟用')
            })
              .catch(error => {
                this.useSwalAlertCenter(false, '啟用失敗!', error.response.data.message)
              })
          }
        })
    },

    // (觸發)關閉會員
    deactivateUser(item) {
      this.useSwalAlertWarning('關閉驗證', `你確定要關閉 ${item.firstname}${item.lastname} 的實名驗證嗎?`)
        .then(result => {
          if (result.value) {
            this.setCustomerUpdate({
              user_id: item.id,
              data: {
                kyc_status: '未驗證',
                reset: false,
              },
            }).then(() => {
              this.refetchData()
              this.useSwalAlertCenter(true, '關閉成功!', '實名驗證已關閉')
            })
              .catch(error => {
                this.useSwalAlertCenter(false, '關閉失敗!', error.response.data.message)
              })
          }
        })
    },

    // (觸發)刪除驗證圖片
    submitRemoveKycImage(item, type) {
      const kycType = this.kycTypeOptions.find(kyc => kyc.key === type)

      this.useSwalAlertWarning('刪除圖片', `你確定要移除${item.firstname}${item.lastname}的 ${kycType ? kycType.label : type} 驗證圖片嗎?`)
        .then(result => {
          if (result.value) {
            this.setCustomerImageDelete({
              user_id: item.id,
              data: {
                type,
              },
            }).then(() => {
              this.refetchData()
              this.useSwalAlertCenter(true, '刪除成功!', `${kycType ? kycType.label : type} 驗證圖片已刪除`)
            })
              .catch(error => {
                this.useSwalAlertCenter(false, '刪除失敗!', error.response.data.message)
              })
          }
        })
    },
  },
  setup() {
    const CUSTOMER_BRANCH_STORE_MODULE_NAME = 'branch-customer'

    if (!store.hasModule(CUSTOMER_BRANCH_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_BRANCH_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_BRANCH_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_BRANCH_STORE_MODULE_NAME)
    })

    const {
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      refetchData,
      refetchTable,
      searchStatus,
      searchKycStatus,
      timeRange,

      getCustomerListData,
      setCustomerUpdate,
      setCustomerImageDelete,
    } = useCustomerList()

    const {
      ui,
      statusOptions,
      refonlineTime,
      onlineTime,
      updateOnline,
      syncObject,
      kycTypeOptions,
      kycStatusOptions,
    } = useCustomerSetting()

    const {
      useAlertToast,
    } = useAlert()

    const dateConfig2 = {
      locale: language.zh_tw,
      enableTime: false,
      mode: 'range',
      dateFormat: 'Y-m-d',
    }

    return {
      ui,
      useAlertToast,
      dateConfig2,
      statusOptions,
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      refetchData,
      refetchTable,
      searchStatus,
      searchKycStatus,
      timeRange,

      getCustomerListData,
      setCustomerUpdate,
      setCustomerImageDelete,
      refonlineTime,
      onlineTime,
      updateOnline,
      syncObject,
      kycTypeOptions,
      kycStatusOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
.dot-green-selected {
  background:radial-gradient(circle closest-side at center,#66ff66,#1bb425);
}

.dot-yellow-selected {
  background:radial-gradient(circle closest-side at center,#f6e9ad,#f6bc01);
}

.dot-red-selected {
  background:radial-gradient(circle closest-side at center,#f6adad,#f32929);
}

.user-state-icon {
  max-width: 24px;
  margin-right: 8px;
}

.user-state-alert {
  margin-left: 5px;
  position: relative;
  .alert-icon {
    position: absolute;
    bottom: -5px;
    right: -8px;
    width: 18px;
    transform: scale(1.05);
    animation: btn-breathe .8s linear infinite;
  }

  .success-icon {
    position: absolute;
    bottom: -3px;
    right: -6px;
    width: 12px;
  }
}

.user-state-icon-hidden {
  opacity: 0 !important;
}

.kyc-img-area {
  height: 140px;
  border: 1px solid #bebebe91;
  border-radius: 8px;
}

.kyc-img-thumbnail {
  width: 100%;
  max-height: 140px;
  height: auto;
  object-fit: contain;
  padding: 5px;
}

.full-placeholder {
  min-height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.image-view {
  position: relative;
  .image-view-remove {
    position: absolute;
    top: -10px;
    right: -6px;
    color: white;
    border-radius: 5px;
    width: 20px;
    height: 20px;
    text-align: center;
    z-index: 10;
    opacity: 0;
    background-color:#b9b9b9c2;
    &:hover {
      background-color: #c40c0cb3;
    }
  }

  &:hover {
    border-radius: 8px;
    border: #4683bb 1px solid;
    margin: -1px;
    .image-view-remove {
      opacity: 1;
    }
  }
}
</style>
