import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    windowVisibilityState: 'visible',
    shallShowOverlay: false,
    notFoundImg: '/admin/images/common/notfound.png',
    ability: {
      state: false,
      // config: 'none',
      // question: 'none',
      // user: 'none',
    },
    themeImages: {
      notFoundImg: require('@/assets/images/commen/notfound.png'),
      loadingImg: require('@/assets/images/commen/loading.png'),
      noImg: require('@/assets/images/commen/noImage.svg'),
      emptyImg: require('@/assets/images/commen/image.svg'),
      infoImg: require('@/assets/images/commen/notifyBlue.png'),
      fireImg: require('@/assets/images/commen/fire.png'),
      missImg: require('@/assets/images/commen/missed.svg'),
      lockImg: require('@/assets/images/commen/lock.svg'),
      errorImg: require('@/assets/images/commen/error.svg'),
    },
    userData: JSON.parse(localStorage.getItem('userData')) ? JSON.parse(localStorage.getItem('userData')) : '',
    isSocketInitialized: false,
    todoFullScreen: JSON.parse(localStorage.getItem('todoFullScreen')) ? JSON.parse(localStorage.getItem('todoFullScreen')) : false,
    customerFullScreen: JSON.parse(localStorage.getItem('customerFullScreen')) ? JSON.parse(localStorage.getItem('customerFullScreen')) : false,
    openAIFullScreen: JSON.parse(localStorage.getItem('openAIFullScreen')) ? JSON.parse(localStorage.getItem('openAIFullScreen')) : false,
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    // currentVisibilityState: state => {
    //   // const { windowWidth } = state
    //   // if (windowWidth >= $themeBreakpoints.xl) return 'xl'
    //   // if (windowWidth >= $themeBreakpoints.lg) return 'lg'
    //   // if (windowWidth >= $themeBreakpoints.md) return 'md'
    //   // if (windowWidth >= $themeBreakpoints.sm) return 'sm'
    //   // return 'xs'
    // },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    UPDATE_WINDOW_VISIBILITY_STATE(state, val) {
      state.windowVisibilityState = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    UPDATE_USERDATA_STATE(state, val) {
      state.userData = val
    },
    SET_SOCKET_INIT(state, val) {
      state.isSocketInitialized = val
    },
    UPDATE_TODO_FULLSCREEN(state, val) {
      state.todoFullScreen = val
    },
    UPDATE_CUSTOMER_FULLSCREEN(state, val) {
      state.customerFullScreen = val
    },
    UPDATE_OPENAI_FULLSCREEN(state, val) {
      state.openAIFullScreen = val
    },
  },
  actions: {
    // (是否)socket function存在
    isSocketFunctionExist(state, name) {
      if (typeof window.socket !== 'undefined' && typeof window.socket[name] === 'function') return true
      return false
    },
  },
}
