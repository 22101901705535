<template>
  <div
    class="chat-app"
    style="height: inherit"
  >
    <div
      class="body-content-overlay"
      :class="{'show': shallOpenCustomerAddSidebarState || shallShowActiveChatUpdateSidebar || shallShowActiveTodoListSidebar || mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar=shallShowActiveTodoListSidebar=shallShowActiveChatUpdateSidebar=shallOpenCustomerAddSidebarState=false"
    />

    <section class="chat-app-window">

      <!-- 開始新增客戶 -->
      <div
        v-show="!activeInfo.data && !activeChartShow"
        class="start-chat-area"
      >
        <div
          class="quick-create-area"
          @click="openCustomerQuickBaseModal"
        >
          <!-- <customer-quick-add
            ref="customerQuickAdd"
            @open-chat="openChatOfContact"
          /> -->

          <customer-quick-base
            ref="customerQuickBase"
            @open-chat-contact="openChatOfContact"
            @refresh-customer-list="refreshCustomerList"
          />
        </div>

        <div class="start-chat-label">
          <div
            class="mb-1 start-chat-icon"
            @click="startConversation"
          >
            <feather-icon
              icon="MessageSquareIcon"
              size="56"
            />
          </div>

          <div class="quick-area mb-25">
            <h4
              class="sidebar-toggle start-chat-text d-lg-block d-none mx-50"
              @click="openCustomerQuickBaseModal"
            >
              快速新增
            </h4>

            <h4
              class="sidebar-toggle start-chat-text d-lg-none d-block mx-50"
              @click="startConversation"
            >
              開始使用客戶列表
              <!-- {{ activeInfoBusy }} / {{ activeRemarkBusy }} -->
            </h4>
          </div>
        </div>
      </div>

      <div
        v-if="activeInfo.data"
        class="double-view"
      >
        <!-- 客戶備註列表 -->
        <div class="active-chat animate__animated animate__fadeIn">
          <div class="chat-navbar">
            <div v-if="!searchMode">
              <header
                v-if="selectedReamrk.length === 0"
                class="chat-header"
              >

                <div class="d-flex align-items-center">

                  <div class="sidebar-toggle d-block d-lg-none mr-1">
                    <feather-icon
                      icon="MenuIcon"
                      class="cursor-pointer"
                      size="21"
                      @click="mqShallShowLeftSidebar = true"
                    />
                  </div>

                  <div class="go-back mr-1 d-none d-lg-block">
                    <feather-icon
                      :icon="'ChevronLeftIcon'"
                      size="20"
                      class="align-bottom cursor-pointer"
                      @click="() => {
                        activeInfo = {}
                        $router.push({ name: 'admin-customer' })
                      }"
                    />
                  </div>

                  <h5
                    class="mb-0 mt-25 email-subject cursor-pointer"
                  >
                    <!-- {{ activeInfo.data.name }} -->
                    客戶詳細資料及備註列表
                  </h5>
                </div>

                <div
                  class="d-flex align-items-center"
                >
                  <div
                    v-b-tooltip.hover.v-secondary
                    title="搜尋"
                    class="actions-link-btn-sm"
                    @click="openInputSearch"
                  >
                    <feather-icon
                      icon="SearchIcon"
                      size="17"
                      class="align-middle text-body"
                    />
                  </div>

                  <div class="dropdown">
                    <b-dropdown
                      variant="link"
                      no-caret
                      toggle-class="p-0"
                      right
                    >
                      <template #button-content>
                        <div
                          v-b-tooltip.hover.v-secondary
                          title="更多"
                          class="actions-link-btn-sm"
                        >
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="17"
                            class="align-middle text-body"
                          />
                        </div>
                      </template>

                      <b-dropdown-item
                        class="dropdown-item-area animate__animated animate__fadeIn"
                        @click="shallShowActiveTodoListSidebar = true"
                      >
                        <b-img
                          src="/admin/images/todo/action/todo.svg"
                          class="dropdown-item-btn-image mr-50"
                          rounded
                        />
                        <span>查看待辦</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        class="dropdown-item-area animate__animated animate__fadeIn"
                        @click="submitUpdateSidebar"
                      >
                        <b-img
                          src="/admin/images/todo/action/pen.svg"
                          class="dropdown-item-btn-image mr-50"
                          rounded
                        />
                        <span>編輯</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        class="dropdown-item-area animate__animated animate__fadeIn"
                        @click="submitDeleteCustomer([activeInfo.data.id])"
                      >
                        <b-img
                          src="/admin/images/todo/action/trash-bin.svg"
                          class="dropdown-item-btn-image mr-50"
                          rounded
                        />
                        <span>刪除</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>

                <div
                  class="chat-header-gotop"
                  @click="scrollToTopInChatLog"
                >
                  返回頂部
                </div>
              </header>

              <header
                v-if="selectedReamrk.length > 0"
                class="chat-header"
              >
                <div class="d-flex align-items-center">
                  <b-button
                    variant="primary"
                    type="submit"
                    class="text-nowrap"
                    @click="$bvModal.show('remarkDeleteModal')"
                  >
                    刪除 {{ selectedReamrk.length }}
                  </b-button>
                </div>

                <div class="d-flex align-items-center">
                  <b-button
                    variant="flat-primary"
                    type="submit"
                    class="text-nowrap"
                    @click="() => {
                      selectedReamrk = []
                    }"
                  >
                    取消
                  </b-button>
                </div>
              </header>
            </div>

            <div v-else>
              <header class="chat-header">

                <div class="d-flex align-items-center w-100">
                  <b-input-group class="input-group-merge w-100">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="SearchIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      ref="inputSearch"
                      v-model="searchQuery"
                      placeholder="搜尋..."
                      debounce="500"
                      @input="inputRemarkSearch"
                    />
                    <b-input-group-append is-text>
                      <small class="text-muted">{{ searchIndex }} / {{ searchResults.length }}</small>
                    </b-input-group-append>
                  </b-input-group>
                </div>

                <div class="text-nowrap d-flex align-items-center">
                  <div class="d-flex align-items-center">
                    <div
                      v-b-tooltip.hover.v-secondary
                      title="下一個結果"
                      class="actions-link-btn-sm ml-50"
                      @click="searchNextReult('next')"
                    >
                      <feather-icon
                        icon="ChevronDownIcon"
                        size="24"
                        class="actions-link-btn-image"
                      />
                    </div>

                    <div
                      v-b-tooltip.hover.v-secondary
                      title="上一個結果"
                      class="actions-link-btn-sm ml-50"
                      @click="searchNextReult('pre')"
                    >
                      <feather-icon
                        icon="ChevronUpIcon"
                        size="24"
                        class="actions-link-btn-image"
                      />
                    </div>

                    <div
                      v-b-tooltip.hover.v-secondary
                      title="取消"
                      class="actions-link-btn-sm ml-50"
                      @click="cancelSearchReult"
                    >
                      <feather-icon
                        icon="XIcon"
                        size="24"
                        class="actions-link-btn-image"
                      />
                    </div>
                  </div>
                </div>
              </header>
            </div>
          </div>

          <vue-perfect-scrollbar
            ref="refChatLogPS"
            :settings="perfectScrollbarSettings"
            class="user-chats user-chats-remark"
            @ps-scroll-y="chatScrollHandle"
          >
            <customer-log
              ref="refChatLog"
              :customer-info="activeInfo"
              :customer-remark="activeRemark"
              :customer-input="chatInputMessage"
              :search-point="searchPoint"
              :reply-id="replyId"
              :selected-remark-list="selectedReamrk"
              :random-key="randomKey"
              :profile-user-data="profileUserData"
              :is-info-loading="activeInfoBusy"
              :is-remark-loading="activeRemarkBusy"
              :shall-show-active-todo-list-sidebar.sync="shallShowActiveTodoListSidebar"
              @selected-remark-index="selectedRemarkIndex"
              @remove-remark-index="removeRemarkIndex"
              @create-remark-data="createMessage"
              @reply-remark-data="replyMessage"
              @update-remark-data="updateMessage"
              @update-remark-message="updateMessageText"
              @update-remark-append="updateMessageAppend"
              @delete-remark-data="deleteMessage"
              @emit-update-sidebar="submitUpdateSidebar"
              @emit-update-busy="updateCustomerBusy"
              @emit-scroll-remark-data="scrollToElement"
              @update-refresh="updateRefresh"
            />
            <!-- @emit-refresh-customer="openChatOfContact" -->
            <!-- {{ activeRemark }} -->
          </vue-perfect-scrollbar>

          <div
            class="go-bottom"
            :class="{ 'go-bottom-show' : !isChatLogAtBottom}"
          >
            <div
              class="go-bottom-btn chatLog"
              @click="scrollToBottomInChatLog"
            >
              <feather-icon
                icon="ArrowDownIcon"
                size="24"
                class="gobottom-btn-icon"
              />
            </div>
          </div>

          <b-form
            class="chat-app-form"
            @submit.prevent
          >
            <div
              v-if="replyId && replyData"
              class="chat-app-form-reply"
            >
              <div class="d-flex align-items-center">
                <div class="mr-1">
                  <b-img
                    src="/admin/images/todo/action/reply1.svg"
                    width="28"
                    rounded
                  />
                </div>
                <div>
                  <div>
                    <small class="text-primary">回覆 {{ replyData.user.name }}</small>
                  </div>
                  <div v-if="replyData.message">
                    {{ replyData.message }}
                  </div>
                  <div
                    v-else
                    class="text-muted"
                  >
                    空訊息
                  </div>
                </div>
              </div>

              <div>
                <div
                  v-b-tooltip.hover.v-secondary
                  title="取消"
                  class="actions-link-btn-sm ml-50"
                  @click="() => replyId = null"
                >
                  <feather-icon
                    icon="XIcon"
                    size="24"
                    class="actions-link-btn-image"
                  />
                </div>
              </div>
            </div>
            <!-- randomIdArray: {{ randomIdArray }} -->

            <div class="chat-app-form-action">
              <label
                class="chat-app-form-icon mb-1"
              >
                <feather-icon
                  icon="LinkIcon"
                  size="20"
                  class="append-icon"
                />
                <input
                  ref="fileInput"
                  type="file"
                  multiple
                  hidden
                  @input="submitUploadFile($event)"
                >
              </label>

              <div class="chat-app-form-icon mb-75">
                <my-emoji-component
                  @select-emoji="selectEmojiIcon"
                >
                  <label
                    slot="button-content"
                    class="cursor-pointer"
                  >
                    <feather-icon
                      icon="SmileIcon"
                      size="20"
                      class="emoji-icon"
                    />
                  </label>
                </my-emoji-component>
              </div>

              <div class="w-100 mr-1">
                <textarea
                  ref="chatInputMessageTextarea"
                  v-model="chatInputMessage"
                  class="w-100 site-input-area"
                  :style="{
                    'min-height': '40px',
                    'color': !isDark ? '#000000' : '#d0d2d6',
                    'border-color': !isDark ? '#d0d2d6' : '#4b5c7c',
                  }"
                  :disabled="isRemarkBusy"
                  placeholder="輸入備註..."
                  @paste="handlePaste"
                  @input="delayedFunction"
                  @keydown="handleKeyDown"
                />
              </div>

              <div
                class="chat-app-form-icon mb-1"
                @click="sendMessage"
              >
                <feather-icon
                  icon="SendIcon"
                  size="20"
                  class="send-icon text-primary"
                />
              </div>
            </div>
          </b-form>
        </div>

        <!-- 客戶待辦列表 -->
        <customer-todo-list
          ref="customerTodoList"
          :customer-info="activeInfo"
          :shall-show-active-todo-list-sidebar.sync="shallShowActiveTodoListSidebar"
          @update-todo-list="updateTodoList"
        />

      </div>

      <!-- 折線圖 -->
      <div
        v-if="!activeInfo.data && activeChartShow"
        class="line-chart-area"
      >
        <customer-line-chart />
      </div>
    </section>

    <!-- 客戶待辦列表 -->
    <customer-active-todo-list-sidedbar
      v-if="activeInfo.data"
      :shall-show-active-todo-list-sidebar.sync="shallShowActiveTodoListSidebar"
      :customer-info="activeInfo || {}"
      :customer-todo="activeTodo || {data: [], total: 0}"
      @loading-next-todo-page="loadingNextTodoListPage"
    />

    <customer-active-update-sidedbar
      v-if="activeInfo.data"
      ref="updateSidedbar"
      :shall-show-active-chat-update-sidebar.sync="shallShowActiveChatUpdateSidebar"
      :customer-info="activeInfo || {}"
      @update-refresh="updateRefresh"
    />

    <portal to="content-renderer-sidebar-left">
      <customer-left-sidebar
        ref="customerLeftSidebar"
        :active-chat-contact-id="activeInfo.data ? activeInfo.data.id : null"
        :shall-customer-chart-show-state.sync="activeChartShow"
        :shall-open-customer-add-sidebar-state.sync="shallOpenCustomerAddSidebarState"
        :shall-open-customer-setting-sidebar-state.sync="shallOpenCustomerSettingSidebarState"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @emit-update-sidebar="submitUpdateSidebar"
        @change-active-chart-show="changeActiveChartShow"
        @open-quick-list="openCustomerQuickListModal"
        @clear-active-info-data="clearActiveInfoData"
        @refresh-customer-info="refreshCustomerInfo"
        @open-customer-add-sidebar="openCustomerAddSidebar"
        @open-customer-setting-sidebar="openCustomerSettingSidebar"
        @open-chat="openChatOfContact"
      />
    </portal>

    <!-- 刪除提示彈窗 -->
    <b-modal
      id="remarkDeleteModal"
      no-close-on-backdrop
      centered
      hide-header
    >
      <div class="p-1">
        <h4>你想要刪除 {{ selectedReamrk.length }} 則備註嗎?</h4>
      </div>

      <template #modal-footer>
        <div class="text-right">
          <b-button
            variant="flat-primary"
            @click="closeDeleteModal"
          >
            取消
          </b-button>

          <b-button
            variant="flat-primary"
            :disabled="isRemarkBusy"
            @click="confirmDeleteModal"
          >
            刪除
          </b-button>
        </div>
      </template>

    </b-modal>

    <audio
      ref="audio"
      src="/admin/audio/audio-2.mp3"
    />
  </div>
</template>

<script>
import {
  ref, onUnmounted, nextTick, computed,
} from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import {
  BDropdown, BDropdownItem, BForm, VBTooltip, BButton, BModal, BImg, BInputGroup, BFormInput,
  BInputGroupPrepend, BInputGroupAppend,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { $themeBreakpoints } from '@themeConfig'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import CustomerLeftSidebar from './CustomerLeftSidebar.vue'
import useStoreModule from '../useStoreModule'
import {
  useCustomerList, useCustomerView, useRemarkList,
} from '../useCustomer'
import CustomerActiveTodoListSidedbar from './CustomerActiveTodoListSidedbar.vue'
import CustomerActiveUpdateSidedbar from './CustomerUpdateSidebar.vue'
import CustomerQuickBase from './CustomerQuickBase.vue'
import CustomerLog from './CustomerLog.vue'
import CustomerTodoList from './CustomerTodoList.vue'
import CustomerLineChart from './CustomerLineChart.vue'
import store from '@/store'
import router from '@/router'
import { useAlert, useSwalToast } from '@/libs/mixins/index'
import { blankSocketData } from '@/libs/socket-client'
import MyEmojiComponent from '@/layouts/components/EmojiPicker/EmojiPicker.vue'

export default {
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
    BForm,
    BModal,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BImg,

    VuePerfectScrollbar,

    CustomerLeftSidebar,
    CustomerActiveTodoListSidedbar,
    CustomerActiveUpdateSidedbar,
    CustomerLog,
    CustomerTodoList,
    CustomerQuickBase,
    CustomerLineChart,
    MyEmojiComponent,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  data() {
    return {
      selectedReamrk: [],
      audioLock: false,
      searchMode: false,
      searchIndex: 0,
      searchResults: [],
      searchPoint: null,
      isChatLogAtBottom: false,
      isTodoListAtBottom: false,
      isFirstClick: false,
    }
  },
  computed: {
    isSocketInit() {
      return this.$store.state.app.isSocketInitialized
    },

    replyData() {
      if (this.activeRemark.length <= 0) return null
      const foundRemark = this.activeRemark.find(remark => remark.id === this.replyId)
      if (foundRemark) {
        const resolve = {
          id: foundRemark.id,
          message: this.resolveMegType(foundRemark.message, foundRemark.appends.length, foundRemark.body.images.length),
          user_id: foundRemark.user_id,
          user: foundRemark.user,
        }
        return resolve
      }

      return null
    },
  },
  watch: {
    isSocketInit(newValue) {
      if (newValue) {
        this.resetSocketConnect()
      }
    },
  },
  mounted() {
    this.initSocketFunction()
    if (this.currentId) {
      this.activeInfoBusy = true
      this.getCustomerInfoData({
        customer_id: this.currentId,
      })
        .then(response => {
          this.activeInfo = {
            ...this.resolveCustomerInfoData(response),
            state: true,
          }
          if (!this.chatInputMessage) this.chatInputMessage = this.activeInfo.data.remark_message
          setTimeout(() => this.adjustTextareaHeight(), 200)

          this.activeInfoBusy = false
          if (this.activeRemarkBusy) return
          this.activeRemarkBusy = true
          this.selectedReamrk = []

          this.$store.dispatch('app/isSocketFunctionExist', 'joinCustomerRoom')
            .then(state => {
              if (state) window.socket.joinCustomerRoom(this.currentId)
            })

          if (this.activeInfo.data) setTimeout(() => { this.$refs.chatInputMessageTextarea.focus() }, 200)
          this.refreshRemarkList(this.currentId)
        })
        .catch(() => {
          this.activeInfo = {
            state: true,
          }
          this.$router.push({ name: 'admin-customer' })
        })
      this.mqShallShowLeftSidebar = false
    }
  },
  beforeDestroy() {
    const { blankCustomer, blankUsers } = blankSocketData()
    window.web.customer = { ...blankCustomer }
    window.web.users = { ...blankUsers }
  },
  methods: {
    // (顯示)檔案字數
    resolveFileNameLong(text, length) {
      if (text.length > length * 2) {
        const start = text.slice(0, length)
        const end = text.slice(-length)
        return `${start}...${end}`
      }
      return text
    },

    // (判斷顯示模式)
    resolveMegType(msg, appends, images) {
      if (msg) {
        return this.resolveFileNameLong(msg, 20)
      }

      if (!msg) {
        if (images) return '回覆一則圖片備註'
        if (appends) return '回覆一則檔案備註'
      }
      return null
    },

    // --------------------------------------搜尋-----------------------------------------------
    // (打開)輸入搜尋
    openInputSearch() {
      this.searchMode = true
      setTimeout(() => { this.$refs.inputSearch.focus() }, 200)
    },

    // (輸入)搜尋結果
    inputRemarkSearch() {
      clearTimeout(this.timeSearch)
      this.timeSearch = setTimeout(() => {
        if (!this.searchQuery) {
          this.searchIndex = 0
          this.searchResults = []
          this.scrollToBottomInChatLog()
          return
        }
        this.getCustomerRemarkSearch({
          customer_id: this.activeInfo.data.id,
          queryParams: {
            search: this.searchQuery,
          },
        })
          .then(response => {
            const { data } = response.data.data
            if (data.length > 0) {
              this.searchResults = data.reverse()
              this.searchIndex = data.length
              this.searchPoint = data[data.length - 1].id
              this.scrollToElement(data[data.length - 1].id)
            } else {
              this.searchIndex = 0
              this.searchResults = []
            }
          })
      }, 1000)
    },

    // (搜尋)下一個結果
    searchNextReult(type) {
      const resultsNum = this.searchResults.length
      if (resultsNum === 0) return

      let newIndex = this.searchIndex

      // 计算新的索引位置
      if (type === 'next') {
        newIndex = (this.searchIndex + 1) % (resultsNum + 1)
      } else if (type === 'pre') {
        newIndex = ((this.searchIndex - 2 + resultsNum) % resultsNum) + 1
      }
      this.searchIndex = newIndex <= 0 ? 1 : newIndex

      const newData = parseInt(JSON.parse(JSON.stringify(this.searchIndex)), 10) - 1
      const nextId = this.searchResults[newData].id
      this.searchPoint = nextId
      this.scrollToElement(nextId)
    },

    // (移動至)搜尋結果
    scrollToElement(id) {
      const container = document.querySelector('.user-chats')
      const child = document.getElementById(`remark-${id}`)

      if (container && child) {
        container.scrollTop = child.offsetTop - container.offsetTop
        // child.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
      }
    },

    // (取消)搜尋結果
    cancelSearchReult() {
      this.searchIndex = 0
      this.searchQuery = null
      this.searchMode = false
      this.searchResults = []
      this.searchPoint = null
      if (this.activeInfo.data) setTimeout(() => { this.$refs.chatInputMessageTextarea.focus() }, 200)
    },

    // (滾軸)滾動監測
    chatScrollHandle() {
      if (this.$refs.refChatLogPS && this.$refs.refChatLog) {
        if (this.$refs.refChatLog.closeAction()) this.$refs.refChatLog.closeAction()
        const scrollChatLogEl = this.$refs.refChatLogPS.$el
        const { scrollTop, clientHeight, scrollHeight } = scrollChatLogEl
        this.isChatLogAtBottom = scrollTop + clientHeight >= scrollHeight
      }
    },

    // -----------------------------------備註--------------------------------------------------
    // (調整)備註檔案輸入欄位
    adjustTextareaHeight() {
      if (!this.$refs.chatInputMessageTextarea) return
      this.$nextTick(() => {
        const textarea = this.$refs.chatInputMessageTextarea
        textarea.style.height = '40px'
        if (textarea.scrollHeight < 100) {
          textarea.style.height = `${textarea.scrollHeight}px`
        } else {
          textarea.style.height = '100px'
        }
      })
    },

    // (選擇)備註
    selectedRemarkIndex(index) {
      this.cancelSearchReult()
      const indexInSelectedRemark = this.selectedReamrk.indexOf(index)
      if (indexInSelectedRemark !== -1) {
        this.selectedReamrk.splice(indexInSelectedRemark, 1)
      } else {
        this.selectedReamrk.push(index)
      }
    },

    // (移除)備註
    removeRemarkIndex(index) {
      // this.activeInfo.data.remarks.splice(index, 1)
      this.activeRemark.splice(index, 1)
      this.scrollToBottomInChatLog()
    },

    // (觸發)關閉備註
    closeDeleteModal() {
      this.$bvModal.hide('remarkDeleteModal')
    },

    // (觸發)刪除備註
    confirmDeleteModal() {
      this.isRemarkBusy = true
      // if (this.rightClick.user_id !== this.profileUserData.id) return
      this.setCustomerRemarkDelete({
        customer_id: this.activeInfo.data.id,
        type: 'muti',
        data: {
          remark_id: this.selectedReamrk,
        },
      })
        .then(response => {
          this.useHttpCodeAlert(response)
          this.selectedReamrk = []
          this.isRemarkBusy = false
          this.refreshRemarkList(this.activeInfo.data.id)
          this.closeDeleteModal()
        })
        .catch(error => {
          this.isRemarkBusy = false
          this.useHttpCodeAlert(error.response)
        })
    },

    // (觸發)檔案上傳
    submitUploadFile(event) {
      if (event.target.files.length === 0) return
      this.$refs.refChatLog.importFile(event.target)
      this.$refs.fileInput.value = null
    },

    // (觸發)輸入欄位按鈕
    handleKeyDown(event) {
      if (this.chatInputMessage === '' && (event.key === 'ArrowUp' || event.keyCode === 38)) {
        if (!this.profileUserData.id) return
        const filteredRemarks = this.activeRemark.filter(user => user.user_id === this.profileUserData.id)
        if (filteredRemarks.length <= 0) return
        const lastRemark = filteredRemarks.pop()
        this.$refs.refChatLog.submitEditRemark(lastRemark)
      }
      if (event.shiftKey && event.key === 'Enter') {
        const cursorPosition = event.target.selectionStart
        this.chatInputMessage = `${this.chatInputMessage.slice(0, cursorPosition)}\n${this.chatInputMessage.slice(cursorPosition)}`
        event.preventDefault()
        const newCursorPosition = cursorPosition + 1
        this.$nextTick(() => {
          this.$refs.chatInputMessageTextarea.setSelectionRange(newCursorPosition, newCursorPosition)
        })
      }
      if (event.ctrlKey && event.key === 'Enter') {
        const cursorPosition = event.target.selectionStart
        this.chatInputMessage = `${this.chatInputMessage.slice(0, cursorPosition)}\n${this.chatInputMessage.slice(cursorPosition)}`
        event.preventDefault()
        const newCursorPosition = cursorPosition + 1
        this.$nextTick(() => {
          this.$refs.chatInputMessageTextarea.setSelectionRange(newCursorPosition, newCursorPosition)
        })
      }
      if (!event.shiftKey && event.key === 'Enter') {
        event.preventDefault()
        this.sendMessage()
      }
    },

    // (按下)Ctrl + V
    handlePaste() {
      if (!navigator.clipboard) {
        this.useAlertToast(false, '當前環境不支援該功能')
        return
      }
      navigator.clipboard.read()
        .then(clipboardItems => {
          clipboardItems.forEach(clipboardItem => {
            const { types } = clipboardItem
            if (types.length === 1) {
              const type = types[0]
              if (type === 'image/png' || type === 'image/jpeg') {
                clipboardItem.getType(type).then(blob => {
                  const file = new File([blob], 'pasted_image.png', { type: blob.type })
                  this.$refs.refChatLog.importFile({ files: [file] })
                })
              }
            }
            if (types.length === 2) {
              if (types.includes('text/html') && types.includes('image/png')) {
                const type = types[1]
                clipboardItem.getType(type).then(blob => {
                  const file = new File([blob], 'pasted_html_image.png', { type: blob.type })
                  this.$refs.refChatLog.importFile({ files: [file] })
                })
              }
            }
          })
        })
        .catch(() => {
          this.useAlertToast(false, '無法讀取剪貼板內容')
        })
    },

    // (新增)EMOJI
    selectEmojiIcon(emoji) {
      const textareaRef = this.$refs.chatInputMessageTextarea
      const cursorPosition = textareaRef.selectionStart
      this.chatInputMessage = `${this.chatInputMessage.slice(0, cursorPosition)}${emoji}${this.chatInputMessage.slice(cursorPosition)}`
      const newCursorPosition = cursorPosition + emoji.length
      this.$nextTick(() => {
        textareaRef.setSelectionRange(newCursorPosition, newCursorPosition)
      })
    },

    // (延遲)輸入快取
    delayedFunction() {
      this.adjustTextareaHeight()
      clearTimeout(this.timeCache)
    },

    openCustomerQuickBaseModal() {
      const emptyData = {
        id: null,
        name: '快速新增',
        order: 0,
        content: {
          data: {
            ...this.blankContentData,
          },
        },
      }
      this.$refs.customerQuickBase.handleShowSiteModal(emptyData)
    },

    // (切換)排行榜
    changeActiveChartShow() {
      if (this.activeInfo.data) {
        this.activeInfo = {}
        this.$router.push({ name: 'admin-customer' })
        this.activeChartShow = true
      } else {
        this.activeChartShow = !this.activeChartShow
      }
    },

    // (觸發)更新側欄
    submitUpdateSidebar() {
      // if (!this.shallShowActiveChatUpdateSidebar)
      this.shallShowActiveChatUpdateSidebar = true
      this.$refs.updateSidedbar.getData()
      // setTimeout(() => {
      // }, 1000)
    },

    // (更新)忙碌狀態
    updateCustomerBusy(state) {
      this.activeInfoBusy = state
    },

    // (刪除)客戶
    submitDeleteCustomer(customerIdArr) {
      if (customerIdArr.length <= 0) return

      this.useSwalAlertWarning('刪除客戶', customerIdArr.length > 1 ? `你確定要永久刪除所選 ${customerIdArr.length}個客戶 嗎?` : `你確定要永久刪除客戶 ${this.activeInfo.data.name ? this.activeInfo.data.name : '未命名客戶'} 嗎?`)
        .then(result => {
          if (result.value) {
            this.setCustomerDelete({
              data: {
                customer_id: customerIdArr,
              },
            })
              .then(() => {
                if (customerIdArr.include(this.activeInfo.data.id)) this.clearActiveInfoData()
                this.refreshCustomerList()
                this.useSwalAlertCenter(true, '刪除成功!', '客戶已移除')
              })
              .catch(error => {
                this.useSwalAlertCenter(false, '刪除失敗!', error.response.data.message)
              })
          }
        })
    },

    // (清除)當前
    clearActiveInfoData() {
      this.activeInfo = {
        state: true,
      }
      this.activeInfoBusy = false
    },

    // -------------------------------------------------------------------------------------
    // (開啟)快速新增列表
    openCustomerQuickListModal() {
      this.$refs.customerQuickList.handleShowListModal()
    },

    // --------------------------------------重整------------------------------------------
    // 獲取客戶詳情
    openChatOfContact(customerId, customer) { // , toDown
      if (this.activeInfo.data !== undefined) {
        if (this.activeInfo.data.id !== customerId) {
          this.$refs.customerTodoList.todoListData = []
        }
      }

      // if (this.activeInfo.state !== undefined && this.activeInfo.state === false) return

      if (customer) {
        const responseData = {
          ...customer,
        }
        this.activeInfo = {
          data: {
            ...responseData,
          },
          state: false,
        }
        if (this.$refs.refChatLog) this.$refs.refChatLog.editCol = ''
      }
      this.chatInputMessage = ''

      this.refreshCustomerInfo(customerId)

      // (重整)備註
      if (this.activeRemarkBusy) return
      this.activeRemarkBusy = true
      this.selectedReamrk = []
      this.cancelSearchReult()
      this.refreshRemarkList(customerId)
      this.activeChartShow = false

      this.$store.dispatch('app/isSocketFunctionExist', 'joinCustomerRoom')
        .then(state => {
          if (state) window.socket.joinCustomerRoom(customerId) // resolveResponse.id
        })
      if (this.activeInfo.data) {
        setTimeout(() => {
          // (重整)待辦
          this.refreshCustomerTodo()
          this.$refs.chatInputMessageTextarea.focus()
        }, 200)
      }
    },

    // (重整)更新客戶
    updateRefresh(customerId) {
      this.refreshCustomerList()
      this.refreshCustomerInfo(customerId)
      this.$refs.customerTodoList.refreshTodoListPageData()
    },

    // (重整)客戶列表
    refreshCustomerList(lock) {
      this.audioLock = lock || false
      this.$refs.customerLeftSidebar.refreshCustomerListPageData()
    },

    // (重整)客戶詳情
    refreshCustomerInfo(customerId) {
      if (this.activeInfoBusy) return
      // if (!this.activeInfo.data) return
      this.activeInfoBusy = true
      this.getCustomerInfoData({
        customer_id: customerId,
      })
        .then(response => {
          this.activeInfo = {
            ...this.resolveCustomerInfoData(response),
            state: true,
          }
          setTimeout(() => this.adjustTextareaHeight(), 200)
          this.activeInfoBusy = false
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.activeInfo = {
              state: true,
            }
            this.activeInfoBusy = false
            return
          }
          this.useHttpCodeAlert(error.response)
          this.activeInfo = {}
          this.activeInfoBusy = false
        })
      this.mqShallShowLeftSidebar = false
    },

    // (重整)備註列表
    refreshRemarkList(customerId) {
      // if (this.activeRemarkBusy) return
      // this.activeRemarkBusy = true

      this.getCustomerRemarkList({
        customer_id: customerId,
      })
        .then(response => {
          this.activeRemark = this.resolveRemarkListData(response)
          const userData = response.data.data.user_data
          this.profileUserData = {
            id: userData.id,
            name: userData.name,
            image: null,
          }
          // this.chatInputMessage = this.activeInfo.data.remark_message
          this.activeRemarkBusy = false

          // if (toDown)
          nextTick(() => {
            this.scrollToBottomInChatLog()
            // setTimeout(() => { this.activeRemarkBusy = false }, 1000)
          })
        })
        .catch(error => {
          if (error.response.status === 400) {
            this.activeRemark = []
            this.activeRemarkBusy = false
            return
          }
          this.useHttpCodeAlert(error.response)
        })
      // const resolveData = { remarks: [] }
    },

    // (重整)客戶待辦
    refreshCustomerTodo() {
      if (this.$refs.customerTodoList) this.$refs.customerTodoList.refreshTodoList()
    },

    // (更新)客戶待辦列表
    updateTodoList(todoList, total) {
      // this.activeTodo = todoList
      this.activeTodo = {
        data: todoList,
        total,
      }
    },

    // (加載)客戶下一頁待辦列表
    loadingNextTodoListPage() {
      this.$refs.customerTodoList.refreshTodoListNextPage()
    },

    // (新增)備註
    createRemarkData(remark) {
      const resolveData = this.resolveRemarkInfoData({
        rid: null,
        ...remark,
      })
      const hasId = this.activeRemark.some(item => item.id === resolveData.id)
      if (!hasId) this.activeRemark.push(resolveData)
    },

    // (初始化)Socket
    initSocketFunction() {
      const windowFunction = window.web
      windowFunction.customer = {
        refreshCustomerTodoList: () => {
          if (this.$refs.customerTodoList) this.$refs.customerTodoList.refreshTodoListPageData()
        },
        refreshRemarkList: id => {
          if (this.activeInfo.data && this.activeInfo.data.id === id) this.refreshRemarkList(id)
        },
        refreshCustomerInfo: id => {
          if (this.activeInfo.data && this.activeInfo.data.id === id) this.refreshCustomerInfo(id)
        },
        // refreshCustomerList: audio => {
        //   this.refreshCustomerList(audio)
        // },
        // createRemark: remark => {
        //   if (!remark) return
        //   if (this.activeInfo.data && this.activeInfo.data.id === remark.customer_id) this.createRemarkData(remark.remark_data)
        // },
      }
      windowFunction.users = {
        refreshUserOnlineStatus: (id, status) => {
          const updateRemark = this.activeRemark.map(remark => {
            if (remark.user_id === id) {
              return {
                ...remark,
                user: {
                  ...remark.user,
                  online_status: status,
                },
              }
            }
            return remark
          })
          this.activeRemark = updateRemark
        },
      }
      if (this.isSocketInit) store.commit('app/SET_SOCKET_INIT', false)
    },

    // (重建)Socket連線
    resetSocketConnect() {
      if (this.activeInfo.data) {
        this.$store.dispatch('app/isSocketFunctionExist', 'joinCustomerRoom')
          .then(state => {
            if (state) window.socket.joinCustomerRoom(this.activeInfo.data.id)
          })
      }
      store.commit('app/SET_SOCKET_INIT', false)
    },
  },
  setup() {
    // 註冊模組
    const CUSTOMER_ADMIN_STORE_MODULE_NAME = 'admin-customer'

    if (!store.hasModule(CUSTOMER_ADMIN_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_ADMIN_STORE_MODULE_NAME)
    })

    const currentId = router.currentRoute.params.id
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')

    // Scroll to Bottom ChatLog
    const refChatLogPS = ref(null)
    const refChatLog = ref(null)
    const chatInputMessageTextarea = ref(null)
    const customerLeftSidebar = ref(null)
    const searchQuery = ref(null)
    const replyId = ref(null)

    const activeInfo = ref({})
    const activeRemark = ref([])
    const activeTodo = ref({ data: [], total: 0 })
    const activeInfoBusy = ref(false)
    const activeRemarkBusy = ref(false)
    const activeChartShow = ref(false)
    const profileUserData = ref({})

    const chatInputMessage = ref(' ')

    const blankContentData = {
      name: null,
      email: null,
      admin_brand_id: null,
    }

    const {
      setCustomerUpdate,
      setCustomerDelete,
      setCustomerComplete,
    } = useCustomerList()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const {
      getCustomerInfoData,
      resolveCustomerInfoData,
      resolveCustomerViewData,
    } = useCustomerView()

    const {
      randomKey,
      randomIdArray,
      isRemarkBusy,
      getCustomerRemarkList,
      getCustomerRemarkSearch,
      resolveRemarkListData,
      resolveRemarkInfoData,
      generateRandomString,
      removeRandomIdArray,

      setCustomerRemarkDelete,
    } = useRemarkList()

    const scrollToBottomInChatLog = () => {
      if (!activeInfo.value.data) return
      const scrollEl = refChatLogPS.value.$el || refChatLogPS.value
      scrollEl.scrollTop = scrollEl.scrollHeight
      if (scrollEl) {
        const options = {
          behavior: 'smooth',
          block: 'end',
          inline: 'end',
        }
        scrollEl.scrollIntoView(options)
      }
    }

    const scrollToTopInChatLog = () => {
      if (!activeInfo.value.data) return
      const scrollEl = refChatLogPS.value.$el || refChatLogPS.value
      scrollEl.scrollTop = 0
      if (scrollEl) {
        const options = {
          behavior: 'smooth',
          block: 'end',
          inline: 'end',
        }
        scrollEl.scrollIntoView(options)
      }
    }

    // (送出)文字預覽(V)
    const sendMessage = () => {
      if (!chatInputMessage.value) return
      if (isRemarkBusy.value) return
      const rId = generateRandomString()
      const resolveData = resolveRemarkInfoData({
        rid: rId,
        message: chatInputMessage.value,
        user_id: profileUserData.value.id ? profileUserData.value.id : null,
        user: profileUserData.value,
        created_at: new Date(),
      })
      activeRemark.value.push(resolveData)
      const payload = {
        customer_id: activeInfo.value.data.id,
        data: {
          message: chatInputMessage.value,
          reply_id: replyId.value,
        },
      }
      isRemarkBusy.value = true
      store.dispatch('admin-customer/setCustomerRemarkCreate', payload)
        .then(response => {
          const { data } = response.data
          const resolveResponse = resolveRemarkInfoData({ ...data })
          resolveResponse.user.name = profileUserData.value.name
          // resolveResponse.rid = null
          const index = activeRemark.value.findIndex(item => item.rid === rId)
          if (index !== -1) {
            // activeRemark.value[index] = resolveResponse
            activeRemark.value.splice(index, 1, resolveResponse)
            removeRandomIdArray(rId)

            store.dispatch('app/isSocketFunctionExist', 'createRemarkSelf')
              .then(state => {
                if (state) {
                  window.socket.createRemarkSelf({
                    customer_id: activeInfo.value.data.id,
                    remark_data: {
                      ...resolveResponse,
                    },
                  })
                }
              })
              // .catch(error => {
              //   console.error(error)
              // })
            // resolveResponse.id
            // randomIdArray.value = randomIdArray.value.filter(item => item !== rId)
          }
          isRemarkBusy.value = false
          nextTick(() => {
            scrollToBottomInChatLog()
            chatInputMessage.value = ''
            replyId.value = null
            chatInputMessageTextarea.value.focus()
            chatInputMessageTextarea.value.style.height = '40px'
            customerLeftSidebar.value.refreshCustomerListPageData()
          })
        })
        .catch(error => {
          const index = activeRemark.value.findIndex(item => item.rid === rId)
          if (index !== -1) {
            activeRemark.value.splice(index, 1)
            removeRandomIdArray(rId)
          }
          isRemarkBusy.value = false
          useHttpCodeAlert(error.response)
        })
    }

    // (回覆)備註
    const replyMessage = id => {
      if (!id) return
      replyId.value = id
      if (activeInfo.value.data) setTimeout(() => { chatInputMessageTextarea.value.focus() }, 200)
    }

    // (送出)檔案預覽
    const createMessage = upload => {
      const rId = generateRandomString()
      const resolveData = resolveRemarkInfoData({
        rid: rId,
        ...upload,
        created_at: new Date(),
      })
      randomKey.value = rId
      activeRemark.value.push(resolveData)
      nextTick(() => {
        scrollToBottomInChatLog()
      })
    }

    // (更新)檔案預覽
    const updateMessage = (rId, response) => {
      const index = activeRemark.value.findIndex(item => item.rid === rId)
      if (index !== -1) {
        const newArray = [...activeRemark.value]
        newArray[index] = response
        activeRemark.value = newArray
        removeRandomIdArray(rId)
      }
      nextTick(() => {
        scrollToBottomInChatLog()
        chatInputMessage.value = ''
        replyId.value = null
        customerLeftSidebar.value.refreshCustomerListPageData()
      })
    }

    // (更新)檔案預覽
    const updateMessageAppend = (rId, appendRId, response, remarkData) => { // , response
      const index = activeRemark.value.findIndex(item => item.rid === rId)
      if (index !== -1) {
        const newArray = [...activeRemark.value]
        const newArrayAppends = newArray[index].appends
        const appendIndex = newArrayAppends.findIndex(item => item.rid === appendRId)
        if (appendIndex !== -1) {
          newArrayAppends[appendIndex] = response
          newArrayAppends[appendIndex].rid = null
          newArrayAppends[appendIndex].file = null
          refChatLog.value.removeRandomIdArray(appendRId)
        }
        newArray[index].appends = newArrayAppends
        activeRemark.value = newArray
        if (newArrayAppends.filter(item => item.rid).length === 0) {
          removeRandomIdArray(rId)
          activeRemark.value[index].rid = null
          const resolveRemarkData = {
            ...remarkData,
          }
          resolveRemarkData.user.name = profileUserData.value.name

          this.$store.dispatch('app/isSocketFunctionExist', 'createRemarkSelf')
            .then(state => {
              if (state) {
                window.socket.createRemarkSelf({
                  customer_id: activeInfo.value.data.id,
                  remark_data: {
                    ...resolveRemarkData,
                  },
                })
              }
            })
        }
      }
    }

    // (更新)文字預覽((edit, real, error) / remarkId / 回傳資料)(V)
    const updateMessageText = (state, id, response) => {
      const index = activeRemark.value.findIndex(item => item.id === id)
      if (index !== -1) {
        const newArray = [...activeRemark.value]
        if (!state) {
          newArray[index].message = response
          activeRemark.value = newArray
          return
        }

        if (state === 'edit') {
          newArray[index].message = response
          activeRemark.value = newArray
          return
        }

        if (state === 'real') {
          newArray[index] = response
          activeRemark.value = newArray
          return
        }

        if (state === 'error') {
          newArray[index].message = response.message
          activeRemark.value = newArray
          chatInputMessage.value = response.input
          useHttpCodeAlert(response.error)
        }
      }
    }

    // (刪除)檔案預覽
    const deleteMessage = rId => {
      const index = activeRemark.value.findIndex(item => item.rid === rId)
      if (index !== -1) {
        activeRemark.value.splice(index, 1)
      }
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const shallOpenCustomerAddSidebarState = ref(false)
    const shallOpenCustomerSettingSidebarState = ref(false)

    const openCustomerAddSidebar = () => {
      shallOpenCustomerAddSidebarState.value = true
    }

    const openCustomerSettingSidebar = () => {
      shallOpenCustomerSettingSidebarState.value = true
    }

    // Active Chat Contact Details
    const shallShowActiveTodoListSidebar = ref(false)
    const shallShowActiveChatUpdateSidebar = ref(false)

    // UI + SM Devices
    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    const startConversation = () => {
      if (store.state.app.windowWidth < $themeBreakpoints.lg) {
        mqShallShowLeftSidebar.value = true
      }
    }
    // ----------------------------------------------------------------------------------------------

    return {
      isDark,

      blankContentData,

      currentId,
      refChatLog,
      refChatLogPS,
      isRemarkBusy,
      customerLeftSidebar,
      chatInputMessageTextarea,
      searchQuery,
      replyId,

      activeInfo,
      activeRemark,
      activeTodo,
      activeInfoBusy,
      activeRemarkBusy,
      activeChartShow,
      profileUserData,
      chatInputMessage,
      sendMessage,
      createMessage,
      replyMessage,
      updateMessage,
      updateMessageAppend,
      updateMessageText,
      deleteMessage,
      getCustomerRemarkList,
      getCustomerRemarkSearch,
      resolveRemarkListData,
      resolveRemarkInfoData,
      getCustomerInfoData,
      resolveCustomerInfoData,
      resolveCustomerViewData,
      setCustomerRemarkDelete,

      shallOpenCustomerAddSidebarState,
      shallOpenCustomerSettingSidebarState,
      openCustomerAddSidebar,
      openCustomerSettingSidebar,

      // Active Chat Contact Details
      shallShowActiveTodoListSidebar,
      shallShowActiveChatUpdateSidebar,

      // UI
      perfectScrollbarSettings,
      scrollToBottomInChatLog,
      scrollToTopInChatLog,

      // UI + SM Devices
      startConversation,
      mqShallShowLeftSidebar,

      // -------------------
      setCustomerUpdate,
      setCustomerDelete,
      setCustomerComplete,

      useAlertToast,
      useHttpCodeAlert,

      randomIdArray,
      randomKey,
    }
  },
}
</script>

<style lang="scss">
.dark-layout {
  .content-area-wrapper{
    .vue-country-select{
      .country-name {
        color: #e4e5eb;
      }
      .dropdown:hover, .dropdown.open {
        background-color: #1b2337;
      }
      .dropdown-container {
        background-color: #344464;
      }
      .dropdown-search-input {
        background-color: #344464;
        color: #e4e5eb;
      }
    }
  }
}
.content-area-wrapper{
  .vue-country-select{
    .dropdown-container {
      width: 300px;
    }
  }
}
</style>

<style lang="scss" scoped>
.line-chart-area {
  position: relative;
  overflow-y: scroll;
  padding: 20px;
  // background-color: #88c7db;
  // .line-chart-create-area {
  //   // background-color: #24c8ff;
  //   // padding: 20px;
  // }
}

.quick-area {
  max-width: 500px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.email-subject {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.site-input-area {
  border: none;
  background: transparent;
  border-radius: 0;
  resize: none;
  height: 30px;
  margin-top: 15px;
  color: #d0d2d6;
  padding: 10px;
  // margin-bottom: -5px;
  border: 1px #d0d2d6 solid;
  border-radius: 5px;
  &:focus {
    box-shadow: none;
    border-color: #719ECE;
  }
  &:focus-visible {
    outline: none;
  }
  &::-webkit-scrollbar {
    width: 0;
  }
}

.chat-app-form-icon {
  // background-color: aqua;
  // align-self: center;
  text-wrap: nowrap;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
  .append-icon {
    transition: transform 0.3s ease-in-out;
    &:hover {
      transform: scale(1.2);
    }
  }

  .emoji-icon {
    transition: transform 0.3s ease-in-out;
    &:hover {
      transform: scale(1.2);
    }
  }

  .send-icon {
    transition: transform 0.3s ease-in-out;
    &:hover {
      transform: scale(1.2);
    }
  }
}

.go-bottom {
  position: relative;
  opacity: 0;
  transition: opacity 0.5s ease;
  .go-bottom-btn {
    background-color: rgba(80, 80, 80, 0.398);
    cursor: pointer;
    position: absolute;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    bottom: 10px;
    width: 40px;
    height: 40px;
    &:hover {
      background-color: rgba(119, 119, 119, 0.633);
    }
    .gobottom-btn-icon {
      color: white;
      font-weight: bolder;
    }
  }
}

.go-bottom-show {
  opacity: 1;
}

.chat-header {
  position: relative;
  .chat-header-gotop {
    position: absolute;
    z-index: 3;
    width: 100%;
    // bottom: -100%;
    top: 100%;
    left: 0;
    background-color: rgba(125, 124, 124, 0.105);
    color: rgb(255, 255, 255);
    text-align: center;
    opacity: 0;
    transition: opacity 0.5s ease;
    padding: 5px;
    &:hover {
      cursor: pointer;
      background-color: rgba(80, 80, 80, 0.398);
      opacity: 1;
    }
  }
}

.start-chat-area {
  position: relative;
  .quick-create-area {
    pointer-events: visiblePainted;
    position: absolute;
    background-color: #24c8ff1c;
    cursor: pointer;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .quick-create-icon {
    position: absolute;
    cursor: pointer;
    bottom: 10px;
    right: 10px;
    .icon-link-btn {
      .icon-link-btn-image {
        height: 40px;
        width: 40px;
        &:hover {
          animation: rotateAnimation 3s linear infinite;
        }
      }
    }
  }

  .start-chat-label {
    // background-color: red;
    // pointer-events: visiblePainted;
    position: absolute;
    // top: 0;
    // bottom: 0;
    // left: 0;
    // right: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .start-chat-text {
      &:hover {
        background-color: #419fd9 !important;
        color: white !important;
      }
    }
  }
}

.dropdown-item-area {
  transition: transform ease-out 200ms;
  // display: flex;
  // align-items: center;
  .dropdown-item-btn-image {
    width: 14px;
    height: 14px;
  }

  &:hover {
    cursor: pointer;
    .dropdown-item-btn-image {
      transform: scale(1.2);
      animation: btn-breathe-customer-dropdown .8s linear infinite;
    }
  }
}

@keyframes btn-breathe-customer-dropdown {
  0%{ transform: scale(0.8); }
  50%{ transform: scale(1.2); }
  100%{ transform: scale(0.8); }
}

@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(720deg);
  }
}

// .chat-app {
//   position: fixed;
//   top: 0;
//   bottom: 0;
//   right: 0;
//   left: 0;
// }

</style>

<style lang="scss">
@import "~@core/scss/base/my/app-chat.scss";
@import "~@core/scss/base/my/app-customer-list.scss";
</style>
