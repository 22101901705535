<template>
  <div
    class="todo-sidebar"
    :class="{'show': mqShallShowLeftSidebar}"
  >
    <!-- 按鈕列 -->
    <div class="sidebar-search">

      <div class="sidebar-search-all d-flex align-items-center justify-content-center">
        <!-- 機器人設定 -->
        <div
          class="sidebar-search-all d-flex align-items-center justify-content-center cursor-pointer search-icon-div"
          @click="$router.push({ name: 'admin-openAI'})"
        >
          <b-avatar
            id="room-search-chart"
            size="40"
            class="badge-minimal p-25 border search-icon"
            :variant="'light-primary'"
            src="/admin/images/sideIcon/open-ai.svg"
          />

          <b-tooltip
            target="room-search-chart"
            boundary-padding="10"
            boundary="window"
            placement="right"
          >
            機器人設定
          </b-tooltip>
        </div>
      </div>

      <hr
        class="mt-0 mb-25"
        :style="{ 'border-color': '#a4a9b080'}"
      >

      <div class="sidebar-search-area">
        <div class="sidebar-search-content">
          <div class="mt-25" />
          <!-- 創建日期 -->
          <div
            class="d-flex align-items-center justify-content-center cursor-pointer search-icon-div mb-1"
            @click="openCreatedModel"
          >
            <b-avatar
              id="room-search-created"
              size="40"
              class="badge-minimal p-25 border search-icon"
              :variant="timeRange ? 'info' : 'light-primary'"
              src="/admin/images/todo/action/schedule.svg"
            />

            <b-tooltip
              target="room-search-created"
              boundary-padding="10"
              boundary="window"
              placement="right"
            >
              創建日期
            </b-tooltip>
          </div>
        </div>

        <div class="sidebar-search-all d-flex align-items-center justify-content-center flex-column sidebar-search-footer">
          <!-- 滿版模式 -->
          <div
            id="room-full-screen"
            class="d-flex align-items-center icon-expand-link-btn cursor-pointer"
            @click="submitUpdateFullScreen"
          >
            <b-img
              :src="`/admin/images/todo/action/${$store.state.app.roomFullScreen ? 'minimise' : 'expand'}.svg`"
              class="icon-expand-link-btn-image"
              rounded
            />
          </div>

          <b-tooltip
            target="room-full-screen"
            boundary-padding="10"
            boundary="window"
            placement="right"
          >
            {{ $store.state.app.roomFullScreen ? '縮小' : '全屏顯示' }}
          </b-tooltip>
        </div>
      </div>

    </div>

    <!-- 列表 -->
    <div class="sidebar-left">
      <div class="sidebar">
        <div class="sidebar-content">
          <!-- <span class="sidebar-close-icon">
            <feather-icon
              icon="XIcon"
              size="16"
              @click="$emit('update:mq-shall-show-left-sidebar', false)"
            />
          </span> -->

          <!-- Header -->
          <div class="chat-fixed-search">
            <div class="d-flex align-items-center w-100">
              <div
                class="new-chat-btn actions-link-btn"
                @click="$emit('open-openAI-add-sidebar')"
              >
                <div class="d-flex">
                  <div class="chat-logo">
                    <b-img
                      src="/admin/images/logo/logo-dark.png"
                      class="actions-link-btn-image"
                      width="24"
                      rounded
                    />
                  </div>
                  <div class="d-flex align-items-center font-weight-bolder">
                    新聊天室
                  </div>
                </div>
                <b-img
                  src="/admin/images/table/edit3.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>
              <div class="sidebar-profile-toggle ml-50 d-flex">
                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="排序"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  :class="{ 'rotated': isSortDirDesc }"
                  @click="isSortDirDesc = !isSortDirDesc"
                >
                  <b-img
                    src="/admin/images/todo/action/arrows.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- ScrollArea: Chat & Contacts -->
          <vue-perfect-scrollbar
            ref="roomListContent"
            :settings="perfectScrollbarSettings"
            class="chat-user-list-wrapper list-group scroll-area"
          >

            <div class="animate__animated animate__fadeIn">
              <ul
                v-if="openAIRoomData.length > 0"
                class="chat-users-list chat-list media-list"
              >
                <open-ai-room
                  v-for="room, index in openAIRoomData"
                  :id="`room-${index}`"
                  :key="room.id"
                  ref="roomContact"
                  v-contextmenu:contextmenu
                  :room-data="room"
                  tag="li"
                  :class="{'active': activeChatContactId === room.id}"
                  class="chat-contact"
                  :is-chat-contact="activeChatContactId !== room.id"
                  @submit-show-content="id => {
                    targetRouteContent(room)
                  }"
                  @contextmenu.prevent="() => { rightClick = room }"
                >
                  <!-- submitShowContent(id, room) -->
                  <!-- <div
                    v-if="selectedList.length > 0"
                    slot="checkbox"
                  >
                    <b-form-checkbox
                      v-model="selectedList"
                      :value="room.id"
                      name="muti-selected"
                    />
                  </div>

                  <div
                    v-if="selectedList.length > 0"
                    slot="mask"
                    class="chat-contact-mask"
                    @click="submitSelectedOpenAIRoom(room.id)"
                  /> -->
                </open-ai-room>
              </ul>

              <div
                v-if="isBusyLoading || isBusy"
                class="text-center p-2"
              >
                <b-img
                  src="/admin/images/common/loading-2.png"
                  rounded
                  height="30"
                  width="30"
                />
              </div>

            </div>
          </vue-perfect-scrollbar>

          <v-contextmenu
            ref="contextmenu"
            :class="$store.state.appConfig.layout.skin === 'dark' ? 'my-contextmenu-dark' : 'my-contextmenu'"
          >
            <div>
              <div class="context-menu-title mb-50">
                {{ rightClick.room_name }}
              </div>

              <v-contextmenu-item divider />

              <v-contextmenu-item
                class="dropdown-item-area"
                @click="submitDeleteOpenAIRoom(rightClick)"
              >
                <b-img
                  src="/admin/images/todo/action/trash.svg"
                  class="dropdown-item-btn-image mr-50"
                  rounded
                />
                <span>刪除</span>
              </v-contextmenu-item>

              <v-contextmenu-item
                class="dropdown-item-area"
                @click="submitRenameOpenAIRoom(rightClick)"
              >
                <b-img
                  src="/admin/images/table/edit.svg"
                  class="dropdown-item-btn-image mr-50"
                  rounded
                />
                <span>重新命名</span>
              </v-contextmenu-item>

              <v-contextmenu-item
                divider
              />

              <v-contextmenu-item
                class="dropdown-item-area"
                @click="submitShareOpenAIRoom"
              >
                <b-img
                  src="/admin/images/todo/action/share.svg"
                  class="dropdown-item-btn-image mr-50"
                  rounded
                />
                <span>分享連結</span>
              </v-contextmenu-item>
            </div>
          </v-contextmenu>
        </div>

      </div>

      <!-- 創建日期 -->
      <b-modal
        id="sidebarFilterCreated"
        centered
        hide-footer
        header-bg-variant="info"
      >
        <template #modal-title>
          <h4 class="m-0 model-header">
            創建日期
          </h4>
        </template>

        <div class="mb-1">
          <date-range-customize
            ref="dateRangeCustomizeCreated"
            date-range-customize-id="dateRangeCustomizeCreated"
            :use-time="true"
            :init-data="timeRange"
            :max-date-time="moment().format('YYYY-MM-DD HH:mm')"
            @call-back-time="(time) => timeRange = time"
          >
            <template slot="quick-time-btn">
              <div class="mb-50">
                <b-button
                  size="sm"
                  variant="outline-primary"
                  class="mr-50 mb-25"
                  @click="$refs.dateRangeCustomizeCreated.quickTime(0, 'd')"
                >
                  今天
                </b-button>
                <b-button
                  size="sm"
                  variant="outline-primary"
                  class="mr-50 mb-25"
                  @click="$refs.dateRangeCustomizeCreated.quickTime(-1, 'd')"
                >
                  昨天
                </b-button>
                <b-button
                  size="sm"
                  variant="outline-primary"
                  class="mr-50 mb-25"
                  @click="$refs.dateRangeCustomizeCreated.quickTime(-7, 'd')"
                >
                  上周
                </b-button>
                <b-button
                  size="sm"
                  variant="outline-primary"
                  class="mr-50 mb-25"
                  @click="$refs.dateRangeCustomizeCreated.quickTime(-14, 'd')"
                >
                  上兩周
                </b-button>

                <b-button
                  size="sm"
                  variant="outline-primary"
                  class="mr-50 mb-25"
                  @click="getThisMonthRange"
                >
                  本月
                </b-button>
              </div>
            </template>
          </date-range-customize>
          <!-- @call-back-time="(time) => timeRange = time" -->
        </div>

      </b-modal>

    </div>
  </div>
</template>

<script>
import { avatarText } from '@core/utils/filter'
import {
  BTooltip, BButton, VBTooltip, BImg, BModal, BAvatar,
} from 'bootstrap-vue'
import moment from 'moment'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import OpenAiRoom from './OpenAIRoom.vue'
import { useOpenAIRoomList } from '../useOpenAI'
import { useAlert, useSwalToast } from '@/libs/mixins/index'
import DateRangeCustomize from '@/layouts/components/Time/DateRangeCustomize.vue'
import router from '@/router'

export default {
  components: {
    BImg,
    BModal,
    BAvatar,
    BTooltip,
    BButton,

    VuePerfectScrollbar,
    OpenAiRoom,
    DateRangeCustomize,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  props: {
    activeChatContactId: {
      type: Number,
      default: null,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      rightClick: {},
      rightClickSearch: {},
      rightClickSearchIndex: null,

      isLoading: false, // true: disable
      dateRangeText: null, // 時間區間 2023-01-01 to 2023-01-02
    }
  },
  computed: {
    isAll() {
      const num = this.currentPage * this.perPage
      if (num > this.totalNum) return false
      return true
    },
  },
  watch: {
    openAIRoomData: {
      handler(newValue) {
        this.selectedList = this.selectedList.filter(selectedItem => {
          const matchingItem = newValue.find(roomItem => roomItem.id === selectedItem)
          return matchingItem !== undefined
        })
      },
      deep: true,
    },

    // customSearch: {
    //   handler(newValue) {
    //     localStorage.setItem('roomFullScreen', JSON.stringify(newValue.useFullScreen))
    //     this.$store.commit('app/UPDATE_CUSTOMER_FULLSCREEN', newValue.useFullScreen)
    //     this.$store.commit('admin-room/UPDATE_USE_AUDIO_STATE', newValue.useAudio)
    //   },
    //   deep: true,
    // },
  },
  mounted() {
    const roomListContentElement = this.$refs.roomListContent.$el
    // const contentAreaElement = document.querySelector('.content-area-wrapper')
    roomListContentElement.addEventListener('scroll', this.isScrollBottom)
    // contentAreaElement.addEventListener('mouseover', this.setHoverElement)
  },
  beforeDestroy() {
    // const contentAreaElement = document.querySelector('.content-area-wrapper')
    // contentAreaElement.removeEventListener('mouseover', this.setHoverElement)
  },
  methods: {
    moment,
    // (獲取)本月
    getThisMonthRange() {
      const today = new Date()
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
      const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)

      // 设置日期对象
      const date = {
        start: firstDayOfMonth,
        end: lastDayOfMonth,
      }
      this.$refs.dateRangeCustomizeCreated.specifiedTime(date.start, date.end)
    },

    targetRouteContent(room) {
      const targetRoute = { name: 'admin-openAI-room-view', params: { id: room.id } }
      if (this.$route.name !== targetRoute.name || parseInt(this.$route.params.id, 10) !== targetRoute.params.id) {
        this.$router.push(targetRoute)
        this.submitShowContent(room.id, room)
      }
    },

    // (觸發)顯示待按詳情
    submitShowContent(id, room) {
      this.$emit('open-chat', id, room)
    },

    // (觸發)搜尋
    submitSearchContent(search) {
      this.perPage = 15
      const propertiesToCopy = [
        'sortBy',
        'searchQuery',
        'timeRange',
      ]
      // this.sortBy = 'priority'
      // const resolveCache = this.syncObject(this.customSearch, search)
      propertiesToCopy.forEach(property => {
        this[property] = search[property]
      })
    },

    // (觸發)更新滿版模式(O)
    submitUpdateFullScreen() {
      localStorage.setItem('openAIFullScreen', JSON.stringify(!this.$store.state.app.openAIFullScreen))
      this.$store.commit('app/UPDATE_OPENAI_FULLSCREEN', !this.$store.state.app.openAIFullScreen)
    },

    // -----------------------------------------------------------------------------------------------
    // (觸發)分享
    submitShareOpenAIRoom() {
      if (!navigator.share) {
        this.useAlertToast(false, '當前環境不支援該功能')
        return
      }
      const url = `/admin/openAI/room?fid=${this.rightClick.id}`
      navigator.share({
        title: '曠世國際管理後台',
        text: '我在AI聊天室列表找到一個有趣的內容，趕緊來看看吧',
        url,
      })
    },

    // (觸發)刪除
    submitDeleteOpenAIRoom(room) {
      const matchingOpenAIRoom = room

      this.useSwalAlertWarning('刪除AI聊天室', `你確定要永久刪除AI聊天室 ${matchingOpenAIRoom.room_name ? matchingOpenAIRoom.room_name : '未命名AI聊天室'} 嗎?`)
        .then(result => {
          if (result.value) {
            this.setOpenAIRoomDelete({
              room_id: matchingOpenAIRoom.id,
            })
              .then(() => {
                this.getOpenAIRoomListData()
                if (matchingOpenAIRoom.id === this.activeChatContactId) this.$emit('clear-active-info-data')
                this.useSwalAlertCenter(true, '刪除成功!', 'AI聊天室已移除')
              })
              .catch(error => {
                this.useSwalAlertCenter(false, '刪除失敗!', error.response.data.message)
              })
          }
        })
    },

    // (觸發)重新命名
    submitRenameOpenAIRoom(room) {
      this.$emit('rename-openAI-room', room.id, room.room_name)
    },

    // (自動)加載資料
    isScrollBottom() {
      const { scrollTop, scrollHeight, clientHeight } = this.$refs.roomListContent.$el
      if (scrollTop + clientHeight === scrollHeight && this.isAll) {
        this.currentPage += 1
      }
    },

    // (開啟)創建時間篩選器
    openCreatedModel() {
      this.$bvModal.show('sidebarFilterCreated')
      setTimeout(() => { this.$refs.dateRangeCustomizeCreated.initDataTime() }, 200)
    },

    refreshOpenAIRoomListPageData() {
      this.getOpenAIRoomListData()
    },
  },
  setup() {
    const searchOpenAIRoom = router.currentRoute.query

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const {
      isBusy,
      isBusyLoading,
      openAIRoomData,
      selectedList,
      totalNum,
      perPage,
      sortBy,
      isSortDirDesc,
      currentPage,
      searchQuery,
      timeRange,

      refetchData,
      syncObject,

      setOpenAIRoomDelete,
      setOpenAIRoomUpdate,
      getOpenAIRoomListData,
      getOpenAIRoomListNextData,
    } = useOpenAIRoomList()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    if (searchOpenAIRoom.fid) {
      searchQuery.value = `#${searchOpenAIRoom.fid}`
    }

    getOpenAIRoomListData()

    return {
      perfectScrollbarSettings,

      isBusy,
      isBusyLoading,
      totalNum,
      perPage,
      sortBy,
      isSortDirDesc,
      currentPage,
      searchQuery,
      timeRange,

      openAIRoomData,
      selectedList,

      useAlertToast,
      useHttpCodeAlert,

      avatarText,
      syncObject,

      refetchData,
      setOpenAIRoomUpdate,
      setOpenAIRoomDelete,
      getOpenAIRoomListData,
      getOpenAIRoomListNextData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-context.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include'; // Bootstrap includes
@import '~@core/scss/base/components/include'; // Components includes

.new-chat-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  padding: 8px 10px;
  width: 100%;
  .chat-logo {
    position: relative;
    width: 30px;
    height: 30px;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 50%;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      border-radius: 50%;
      box-shadow: inset 0 0 0 1px rgba(0,0,0,.1);
      // --tw-shadow-colored: inset 0 0 0 1px var(--tw-shadow-color);
    }
  }
  &:hover {
    cursor: pointer;
    background-color: #9de0f64f;
  }
}

.dropdown-item-area {
  transition: transform ease-out 200ms;
  // display: flex;
  // align-items: center;
  .dropdown-item-btn-image {
    width: 12px;
    height: 12px;
  }

  &:hover {
    cursor: pointer;
    .dropdown-item-btn-image {
      transform: scale(1.2);
      animation: btn-breathe-room-dropdown .8s linear infinite;
    }
  }
}

.chat-contact {
  position: relative;
  display: flex;
  align-items: center;
  height: 65px;
}

.context-menu-title {
  padding: 0.26rem 1rem;
  max-width: 150px;
  min-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dropdown-item-area {
  transition: transform ease-out 200ms;
  // display: flex;
  // align-items: center;
  .dropdown-item-btn-image {
    width: 12px;
    height: 12px;
  }

  &:hover {
    cursor: pointer;
    .dropdown-item-btn-image {
      transform: scale(1.2);
      animation: btn-breathe-customer-dropdown .8s linear infinite;
    }
  }
}

.sidebar-search-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  // height: 100%;
  height: calc(100% - 70px);
}

.sidebar-search-content {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0;
  }
}

.icon-link-btn {
  .icon-link-btn-image {
    height: 25px;
    width: 25px;
    &:hover {
      animation: rotateAnimation 3s linear infinite;
    }
  }
}

.icon-expand-link-btn {
  .icon-expand-link-btn-image {
    height: 25px;
    width: 25px;
  }
  &:hover {
    animation: btn-breathe-customer-dropdown .8s linear infinite;
  }
}

.search-icon-div {
  transition: transform 0.5s ease-in-out;
  .search-icon:hover {
    // background-color: red;
    transform: scale(1.2);
  }
}

.sidebar-search-footer {
  width: 100%;
  border-top: 1px #a4a9b080 solid;
}

.model-header {
  color: white;
  padding: 3px 0;
}
.rotated {
  transform: rotate(180deg);
  transition: transform 0.3s;
}
@keyframes btn-breathe-customer-dropdown {
  0%{ transform: scale(0.8); }
  50%{ transform: scale(1.2); }
  100%{ transform: scale(0.8); }
}

@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(720deg);
  }
}
</style>
