import { ref, watch, computed } from '@vue/composition-api'
import {
  useTableComponent, useCommenSettings, useAlert,
} from '@/libs/mixins/index'
import store from '@/store'
// import router from '@/router'

export const useCustomerSetting = () => {
  const {
    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  } = useCommenSettings()

  const ui = {
    groupPublic: [{
      true: '啟用', false: '關閉',
    }, {
      true: 'light-success', false: 'light-secondary',
    }],
    onlineStatus: [{
      online: '在線', busy: '忙線', offline: '離線',
    }, {
      online: 'dot-green', busy: 'dot-yellow', offline: 'dot-gray',
    }],
  }

  const publicOptions = [{
    label: '關閉', value: false,
  }, {
    label: '啟用', value: true,
  }]

  const searchDepartment = ref(null)
  // const searchAdminBrand = ref(null)
  const departmentsOptions = ref([])
  const adminBrandOptions = ref([])
  const typeGroupOptions = ref([])
  const todoStatusOptions = ref([])
  const executionTypeGroupOptions = ref([])

  const getMetaListData = () => store.dispatch('api/getMetaList')
  const getConfigData = (...arg) => store.dispatch('api/getConfigData', ...arg)

  const blankSimpleInfo = {
    id: null,
    name: null,
  }

  const blankDescriptionInfo = {
    id: null,
    name: null,
    description: '',
  }

  const blankChartData = {
    series: [0],
    options: {
      grid: {
        show: false,
        padding: {
          left: -15,
          right: -15,
          top: -12,
          bottom: -15,
        },
      },
      colors: ['#24c9ff'],
      plotOptions: {
        radialBar: {
          hollow: {
            size: '22%',
          },
          track: {
            background: '#e9ecef',
          },
          dataLabels: {
            showOn: 'always',
            name: {
              show: false,
            },
            value: {
              show: false,
            },
          },
        },
      },
      stroke: {
        lineCap: 'round',
      },
    },
  }

  const blankClassData = {
    department_id: null,
    name: null,
    description: '',
  }

  const searchOptions = [
    { label: '#ID', value: 'id' },
    { label: '客戶評分', value: 'score' },
    { label: '更新時間', value: 'updated_at' },
    { label: '創建時間', value: 'created_at' },
  ]

  const blankSearchData = {
    name: null,
    image: null,
    sortBy: 'updated_at',
    searchQuery: null,
    searchStatus: null,
    searchDisplay: true,
    searchDisplayself: false,
    searchTimeWarning: false,
    timeRange: null,
    searchTimeDeadline: null,
    searchDepartment: null,
    searchExecutionType: null,
    searchType: null,
    searchAdminBrand: null,
    deadline_at: null,
  }

  const resolveChartDataColor = health => {
    if (health <= 10) return '#ff5b52'
    if (health <= 50) return '#ffbb53'
    if (health < 100) return '#24c9ff'
    if (health === 100) return '#37deb2'
    return '#24c9ff'
  }

  return {
    ui,

    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
    getMetaListData,
    getConfigData,
    searchDepartment,
    departmentsOptions,
    adminBrandOptions,
    typeGroupOptions,
    todoStatusOptions,
    executionTypeGroupOptions,
    publicOptions,

    blankChartData,
    blankClassData,
    blankSimpleInfo,
    blankDescriptionInfo,
    searchOptions,
    blankSearchData,

    resolveChartDataColor,
  }
}

export const useCustomerList = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    timeRange,
  } = useTableComponent()

  const {
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  const {
    blankSimpleInfo,

    adminBrandOptions,
    getMetaListData,
    // getConfigData,
  } = useCustomerSetting()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '品牌', key: 'admin_brand', sortable: true, searchable: true, select: true,
    },
    {
      label: '名稱', key: 'name', sortable: true, searchable: true, select: true,
    },
    {
      label: '地址', key: 'address', sortable: true, searchable: true, select: true,
    },
    {
      label: '電子信箱', key: 'email', sortable: true, searchable: true, select: true,
    },
    {
      label: '國家', key: 'country', sortable: true, searchable: true, select: true,
    },
    {
      label: '上次更新', key: 'updated_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const isBusy = ref(false)
  const isBusyLoading = ref(false)
  const selectedList = ref([])
  const customerData = ref([])
  const searchAdminBrand = ref(null)

  const blankCustomerData = {
    id: null,
    name: null,
    email: null,
    image: null,
    address: null,
    country: null,
    score: null,
    remark: null,
    user: null,
    user_id: null,
    admin_brand: null,
    admin_brand_id: null,
    last_todo: null,
    last_todo_id: null,
    nickName: null,
    contact_method: null,

    created_at: null,
    updated_at: null,
  }

  const dataMeta = computed(() => {
    const localItemsCount = totalNum.value
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * currentPage.value > totalNum.value ? totalNum.value : perPage.value * (currentPage.value - 1) + perPage.value,
      of: totalNum.value,
    }
  })

  const setCustomerCreate = (...arg) => store.dispatch('admin-customer/setCustomerCreate', ...arg)
  const setCustomerUpdate = (...arg) => store.dispatch('admin-customer/setCustomerUpdate', ...arg)
  const setCustomerDelete = (...arg) => store.dispatch('admin-customer/setCustomerDelete', ...arg)

  perPage.value = 15
  sortBy.value = 'updated_at'

  // (初始化)搜尋Meta (customer)
  const initFilterOptions = () => {
    getMetaListData()
      .then(response => {
        // 陣列更新中

        const adminBrands = response.data.data.admin_brands
        adminBrandOptions.value = adminBrands

        store.commit('admin-customer/UPDATE_ADMIN_BRAND', adminBrandOptions.value)

        //     // 判斷是否有在陣列中
        //     const findDepartment = departments.find(el => el.id === this.customerData.department_id)

        //     if (findDepartment === undefined) {
        //       this.customerData.department_id = null
        //     } else {
        //       this.filterCustomerTypeOptions(this.customerData.department_id)
        //       this.filterCustomerExecutionTypeOptions(this.customerData.department_id)
        //     }

        //     const findAdminBrand = adminBrands.find(el => el.id === this.customerData.admin_brand_id)

      //     if (findAdminBrand === undefined) {
      //       this.customerData.admin_brand_id = null
      //     }
      })
  }

  // (初始化)客戶列表
  const getCustomerListInitData = () => {
    initFilterOptions()
    isBusy.value = true
    currentPage.value = 1
    selectedList.value = []

    // 整理filters
    let resolveFilters = ''

    if (searchAdminBrand.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};admin_brand_id: ${searchAdminBrand.value}`
      } else resolveFilters = `admin_brand_id: ${searchAdminBrand.value}`
    }

    // 整理timeRange
    let resolveTimeRange = null
    if (timeRange.value) {
      resolveTimeRange = timeRange.value.split(' 至 ')
      const [start, end] = resolveTimeRange
      if (end) {
        resolveTimeRange = `${start} to ${end}`
      } else resolveTimeRange = `${start}`
    }

    store.dispatch('admin-customer/getCustomerList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: timeRange.value,
      filters: resolveFilters,
      _: Date.now(),
    })
      .then(response => {
        const { data, total } = response.data.data

        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankCustomerData, item),
            // admin_brand_info: syncObject(blankSimpleInfo, store.state['admin-customer'].adminBrand.find(item.AdminBrandId) ? store.state['admin-customer'].adminBrand.find(item.AdminBrandId) : {}),
          }
          return resolve
        })

        customerData.value = resolveData

        totalNum.value = total
        isBusy.value = false
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  // (獲取)下一頁資料
  const getCustomerListNextData = () => {
    if (customerData.value.length > 0) isBusyLoading.value = true

    // 整理filters
    let resolveFilters = ''

    if (searchAdminBrand.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};admin_brand_id: ${searchAdminBrand.value}`
      } else resolveFilters = `admin_brand_id: ${searchAdminBrand.value}`
    }

    // 整理timeRange
    let resolveTimeRange = null
    if (timeRange.value) {
      resolveTimeRange = timeRange.value.split(' 至 ')
      const [start, end] = resolveTimeRange
      if (end) {
        resolveTimeRange = `${start} to ${end}`
      } else resolveTimeRange = `${start}`
    }

    store.dispatch('admin-customer/getCustomerList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: timeRange.value,
      filters: resolveFilters,
    })
      .then(response => {
        isBusy.value = false
        isBusyLoading.value = false
        const { data, total } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankCustomerData, item),
            // admin_brand_info: syncObject(blankSimpleInfo, item.admin_brand_info ? item.admin_brand_info : {}),
          }
          return resolve
        })
        resolveData.forEach(el => {
          customerData.value.push(el)
        })
        totalNum.value = total
      })
  }

  // (獲取)當前數量
  const refreshCustomerListPageData = () => {
    initFilterOptions()

    if (isBusy.value) return
    isBusy.value = true
    // selectedList.value = []
    // 整理filters
    let resolveFilters = ''

    // console.log('整理當前filters', searchAdminBrand.value)

    if (searchAdminBrand.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};admin_brand_id: ${searchAdminBrand.value}`
      } else resolveFilters = `admin_brand_id: ${searchAdminBrand.value}`
    }

    // 整理timeRange
    let resolveTimeRange = null
    if (timeRange.value) {
      resolveTimeRange = timeRange.value.split(' 至 ')
      const [start, end] = resolveTimeRange
      if (end) {
        resolveTimeRange = `${start} to ${end}`
      } else resolveTimeRange = `${start}`
    }

    store.dispatch('admin-customer/getCustomerList', {
      search: searchQuery.value,
      limit: perPage.value, // customerData.value.length === totalNum.value ? totalNum.value + 1 : perPage.value * currentPage.value
      page: 1,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: timeRange.value,
      filters: resolveFilters,
    })
      .then(response => {
        const { data, total } = response.data.data

        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankCustomerData, item),
            admin_brand_info: syncObject(blankSimpleInfo, item.admin_brand ? item.admin_brand : {}),
          }
          return resolve
        })

        customerData.value = resolveData
        totalNum.value = total
        isBusy.value = false
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchData = () => getCustomerListInitData()

  watch([
    searchQuery, timeRange, searchAdminBrand, isSortDirDesc, sortBy,
  ], () => {
    currentPage.value = 1
    selectedList.value = []
    refreshCustomerListPageData()
  })

  watch([currentPage], () => {
    if (currentPage.value === 1) return
    getCustomerListNextData()
  })

  return {
    isBusy,
    isBusyLoading,
    customerData,
    searchAdminBrand,
    adminBrandOptions,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    selectedList,
    refetchData,
    // refetchTable,

    setCustomerCreate,
    setCustomerUpdate,
    setCustomerDelete,
    getCustomerListInitData,
    getCustomerListNextData,
    refreshCustomerListPageData,
    useHttpCodeAlert,
    syncObject,
  }
}

export const useCustomerView = () => {
  const isImageBusy = ref(false)
  const showCustomerDetails = ref(false)
  const customerData = ref(null)

  const {
    syncObject,
  } = useCommenSettings()

  const blankCustomerData = {
    id: null,
    name: null,
    email: null,
    image: null,
    address: null,
    country: null,
    score: null,
    remark: null,
    user: null,
    user_id: null,
    admin_brand: null,
    admin_brand_id: null,
    last_todo: null,
    last_todo_id: null,
    nickName: null,
    contact_method: null,

    created_at: null,
    updated_at: null,
  }

  // const {
  //   blankCustomerData,
  // } = useCustomerList()

  // const blankAppendsData = {
  //   id: null,
  //   extension: null,
  //   name: null,
  // }

  const getCustomerInfoData = (...arg) => store.dispatch('admin-customer/getCustomerData', ...arg)
  const setCustomerProfileUpdate = (...arg) => store.dispatch('admin-customer/setCustomerProfileUpdate', ...arg)
  const setCustomerProfileDelete = (...arg) => store.dispatch('admin-customer/setCustomerProfileDelete', ...arg)

  const resolveCustomerInfoData = response => {
    const { data } = response.data
    const resolveData = {
      data: {
        ...syncObject(blankCustomerData, data),
      },
    }
    return resolveData
  }

  return {
    isImageBusy,
    blankCustomerData,
    showCustomerDetails,
    customerData,
    resolveCustomerInfoData,

    getCustomerInfoData,
    setCustomerProfileUpdate,
    setCustomerProfileDelete,
  }
}

export const useCustomerTodoList = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    timeRange,
  } = useTableComponent()

  const {
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  const {
    blankSimpleInfo,
    blankDescriptionInfo,

    departmentsOptions,
    adminBrandOptions,
    executionTypeOptions,
    typeOptions,
    typeGroupOptions,
    executionTypeGroupOptions,
    getMetaListData,
    // getConfigData,
  } = useCustomerSetting()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '部門', key: 'department', sortable: true, searchable: true, select: true,
    },
    {
      label: '名稱', key: 'name', sortable: true, searchable: true, select: true,
    },
    {
      label: '說明', key: 'description', sortable: true, searchable: true, select: true,
    },
    {
      label: '上次更新', key: 'updated_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const isBusy = ref(false)
  const isBusyLoading = ref(false)
  const todoListData = ref([])
  const priorityLists = ref({})
  const priorityOptions = ref([])
  const statusLists = ref({})
  const statusOptions = ref([])
  const groupLists = ref({})
  const groupOptions = ref([])
  const searchCustomer = ref(null)
  const timeWarningOptions = [{ label: '無', value: false }, { label: '時間少於6H', value: true }]

  const blankTodoData = {
    id: null,
    title: '未命名標題',
    description: '',
    admin_brand_id: null,
    department_id: null,
    created_user_id: null,
    type_id: null,
    execution_type_id: null,
    review_user_id: null,
    review_at: null,
    has_view: false,
    display_status: false,

    priority: 0,
    status: 'not-completed',
    client_name: null,
    completed_at: null,
    deadline_at: null,
    scheduled_at: null,
    created_at: null,
    updated_at: null,
  }

  const blankViewInfo = {
    total: 0,
  }

  const blankPriorityInfo = {
    title: null,
    sign: null,
    description: null,
    value: 50,
    color: '#FFFFFF',
    font_color: '#333333',
  }

  const dataMeta = computed(() => {
    const localItemsCount = totalNum.value
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * currentPage.value > totalNum.value ? totalNum.value : perPage.value * (currentPage.value - 1) + perPage.value,
      of: totalNum.value,
    }
  })

  const setTodoCreate = (...arg) => store.dispatch('admin-customer/setTodoCreate', ...arg)

  perPage.value = 10
  sortBy.value = 'updated_at'

  // (初始化)搜尋Meta (todo)
  const initFilterOptions = () => {
    getMetaListData()
      .then(response => {
        const { departments } = response.data.data
        departmentsOptions.value = departments

        const adminBrands = response.data.data.admin_brands
        adminBrandOptions.value = adminBrands

        const todoTypes = response.data.data.todo_types
        typeGroupOptions.value = todoTypes

        const executionTypes = response.data.data.execution_types
        executionTypeGroupOptions.value = executionTypes
      })
  }

  // (初始化)待辦列表
  const getTodoListInitData = () => {
    initFilterOptions()
    isBusy.value = true
    currentPage.value = 1
    todoListData.value = []
    // ctx, callback
    // if (store.state.app.ability.user === 'none') {
    //   router.replace({ name: 'admin-home' })
    //   return
    // }

    // 整理filters
    let resolveFilters = ''
    if (searchCustomer.value !== null) {
      resolveFilters = `customer_id: ${searchCustomer.value}`
    }

    // 整理timeRange
    let resolveTimeRange = null
    if (timeRange.value) {
      resolveTimeRange = timeRange.value.split(' 至 ')
      const [start, end] = resolveTimeRange
      if (end) {
        resolveTimeRange = `${start} to ${end}`
      } else resolveTimeRange = `${start}`
    }

    store.dispatch('admin-customer/getTodoList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: timeRange.value,
      filters: resolveFilters,
      allItem: true,
      _: Date.now(),
    })
      .then(response => {
        const {
          data, total, priority, status, groups,
        } = response.data.data

        const statusKeys = Object.keys(status)
        statusOptions.value = statusKeys.map(key => {
          const option = {
            title: status[key].title,
            value: key,
            description: status[key].description,
            color: status[key].color,
            font_color: status[key].font_color,
          }
          statusLists.value[key] = option
          return option
        })

        const priorityKeys = Object.keys(priority)
        priorityOptions.value = priorityKeys.map(key => {
          const option = {
            title: priority[key].title,
            value: parseInt(key, 10),
            description: priority[key].description,
            sign: priority[key].sign,
            color: priority[key].color,
            font_color: priority[key].font_color,
          }
          priorityLists.value[key] = option
          return option
        })

        priorityOptions.value.sort((a, b) => b.value - a.value)

        groupOptions.value = groups.map(item => {
          const option = {
            label: item.name,
            value: item.id,
          }
          return option
        })

        store.commit('admin-customer/UPDATE_PRIORITY_LIST', priorityLists.value)
        store.commit('admin-customer/UPDATE_PRIORITY_OPTIONS', priorityOptions.value)
        store.commit('admin-customer/UPDATE_STATUS_LIST', statusLists.value)
        store.commit('admin-customer/UPDATE_STATUS_OPTIONS', statusOptions.value)
        store.commit('admin-customer/UPDATE_GROUP_OPTIONS', groupOptions.value)

        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankTodoData, item),
            admin_brand_info: syncObject(blankSimpleInfo, item.admin_brand_info ? item.admin_brand_info : {}),
            department_info: syncObject(blankSimpleInfo, item.department_info ? item.department_info : {}),
            created_user_info: syncObject(blankSimpleInfo, item.created_user_info ? item.created_user_info : {}),
            execution_type_info: syncObject(blankDescriptionInfo, item.execution_type_info ? item.execution_type_info : {}),
            type_info: syncObject(blankDescriptionInfo, item.type_info ? item.type_info : {}),
            priority_info: syncObject(blankPriorityInfo, item.priority_info ? item.priority_info : {}),
            status_info: statusLists.value[item.status],
            review_user_info: syncObject(blankSimpleInfo, item.review_user_info ? item.review_user_info : {}),
            view_info: syncObject(blankViewInfo, item.view_info ? item.view_info : {}),
          }
          return resolve
        })

        todoListData.value = resolveData

        totalNum.value = total
        isBusy.value = false
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  // (獲取)下一頁資料
  const getTodoListNextData = () => {
    if (todoListData.value.length > 0) isBusyLoading.value = true

    // 整理filters
    let resolveFilters = ''
    if (searchCustomer.value !== null) {
      resolveFilters = `customer_id: ${searchCustomer.value}`
    }

    // 整理timeRange
    let resolveTimeRange = null
    if (timeRange.value) {
      resolveTimeRange = timeRange.value.split(' 至 ')
      const [start, end] = resolveTimeRange
      if (end) {
        resolveTimeRange = `${start} to ${end}`
      } else resolveTimeRange = `${start}`
    }

    store.dispatch('admin-customer/getTodoList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: timeRange.value,
      filters: resolveFilters,
      allItem: true,
    })
      .then(response => {
        isBusy.value = false
        isBusyLoading.value = false
        const { data, total } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankTodoData, item),
            admin_brand_info: syncObject(blankSimpleInfo, item.admin_brand_info ? item.admin_brand_info : {}),
            department_info: syncObject(blankSimpleInfo, item.department_info ? item.department_info : {}),
            created_user_info: syncObject(blankSimpleInfo, item.created_user_info ? item.created_user_info : {}),
            execution_type_info: syncObject(blankDescriptionInfo, item.execution_type_info ? item.execution_type_info : {}),
            type_info: syncObject(blankDescriptionInfo, item.type_info ? item.type_info : {}),
            priority_info: syncObject(blankPriorityInfo, item.priority_info ? item.priority_info : {}),
            status_info: statusLists.value[item.status],
            review_user_info: syncObject(blankSimpleInfo, item.review_user_info ? item.review_user_info : {}),
            view_info: syncObject(blankViewInfo, item.view_info ? item.view_info : {}),
          }
          return resolve
        })
        resolveData.forEach(el => {
          todoListData.value.push(el)
        })
        totalNum.value = total
      })
  }

  // (獲取)當前數量
  const refreshTodoListPageData = () => {
    initFilterOptions()

    if (isBusy.value) return
    isBusy.value = true
    // 整理filters
    let resolveFilters = ''
    if (searchCustomer.value !== null) {
      resolveFilters = `customer_id: ${searchCustomer.value}`
    }

    // 整理timeRange
    let resolveTimeRange = null
    if (timeRange.value) {
      resolveTimeRange = timeRange.value.split(' 至 ')
      const [start, end] = resolveTimeRange
      if (end) {
        resolveTimeRange = `${start} to ${end}`
      } else resolveTimeRange = `${start}`
    }

    store.dispatch('admin-customer/getTodoList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: 1,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: timeRange.value,
      filters: resolveFilters,
      allItem: true,
    })
      .then(response => {
        const { data, total } = response.data.data

        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankTodoData, item),
            admin_brand_info: syncObject(blankSimpleInfo, item.admin_brand_info ? item.admin_brand_info : {}),
            department_info: syncObject(blankSimpleInfo, item.department_info ? item.department_info : {}),
            created_user_info: syncObject(blankSimpleInfo, item.created_user_info ? item.created_user_info : {}),
            execution_type_info: syncObject(blankDescriptionInfo, item.execution_type_info ? item.execution_type_info : {}),
            type_info: syncObject(blankDescriptionInfo, item.type_info ? item.type_info : {}),
            priority_info: syncObject(blankPriorityInfo, item.priority_info ? item.priority_info : {}),
            status_info: statusLists.value[item.status],
            review_user_info: syncObject(blankSimpleInfo, item.review_user_info ? item.review_user_info : {}),
            view_info: syncObject(blankViewInfo, item.view_info ? item.view_info : {}),
          }
          return resolve
        })

        todoListData.value = resolveData

        totalNum.value = total
        isBusy.value = false
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchData = () => getTodoListInitData()

  watch([searchQuery, isSortDirDesc, sortBy, // , searchCustomer
  ], () => {
    currentPage.value = 1
    refreshTodoListPageData()
  })

  watch([currentPage], () => {
    if (currentPage.value === 1) return
    getTodoListNextData()
  })

  return {
    isBusy,
    isBusyLoading,
    todoListData,
    priorityLists,
    priorityOptions,
    statusLists,
    statusOptions,
    groupLists,
    groupOptions,
    departmentsOptions,
    adminBrandOptions,
    executionTypeOptions,
    typeOptions,
    typeGroupOptions,
    executionTypeGroupOptions,
    timeWarningOptions,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    searchCustomer,
    refetchData,
    // refetchTable,

    setTodoCreate,
    getTodoListInitData,
    getTodoListNextData,
    refreshTodoListPageData,
    useHttpCodeAlert,
    syncObject,
  }
}

export const useRemarkList = () => {
  const isRemarkBusy = ref(false)
  const remarkLists = ref([])
  const remarkText = ref(null)
  const randomIdArray = ref([])
  const randomKey = ref(null)

  // const {
  //   blankSimpleInfo,
  //   blankDescriptionInfo,
  // } = useCustomerSetting()

  const {
    syncObject,
  } = useCommenSettings()

  const blankRemarkData = {
    customer_id: null,
    rid: null,
    id: null,
    ip: null,
    body: {},
    appends: [],
    message: null,
    user: null,
    user_id: null,
    created_at: null,
    updated_at: null,
  }

  const blankUserData = {
    name: null,
    image: null,
    online_status: null,
  }

  const blankBodyData = {
    images: [],
    reply_id: null,
  }

  const blankImageData = {
    append_id: null,
    preview_image: null,
  }

  const blankAppendData = {
    rid: null,
    extension: null,
    id: null,
    file_name: null,
    remark_id: null,
    size: null,
    file: null,
  }

  const judgeTypeOption = [
    'image/png',
    'image/jpeg',
  ]

  const setCustomerRemarkCreate = (...arg) => store.dispatch('admin-customer/setCustomerRemarkCreate', ...arg)
  const setCustomerRemarkUpdate = (...arg) => store.dispatch('admin-customer/setCustomerRemarkUpdate', ...arg)
  const setCustomerRemarkDelete = (...arg) => store.dispatch('admin-customer/setCustomerRemarkDelete', ...arg)

  const getRemarkAppendData = (...arg) => store.dispatch('admin-customer/getRemarkAppendData', ...arg)
  const setRemarkAppendUpload = data => store.dispatch('admin-customer/setRemarkAppendUpload', data)
  const getRemarkAppendDownloadKey = (...arg) => store.dispatch('admin-customer/getRemarkAppendDownloadKey', ...arg)
  // const getRemarkAppendDownload = (...arg) => store.dispatch('admin-customer/getRemarkAppendDownload', ...arg)

  const getCustomerRemarkList = (...arg) => store.dispatch('admin-customer/getCustomerRemarkList', ...arg)
  const getCustomerRemarkSearch = (...arg) => store.dispatch('admin-customer/getCustomerRemarkSearch', ...arg)

  // 產生亂數雜錯
  const generateRandomString = () => {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    let randomString = ''

    do {
      for (let i = 0; i < 20; i += 1) {
        const randomIndex = Math.floor(Math.random() * characters.length)
        randomString += characters[randomIndex]
      }
    } while (randomIdArray.value.includes(randomString))

    randomIdArray.value.push(randomString)
    return randomString
  }

  const removeRandomIdArray = rId => {
    randomIdArray.value = randomIdArray.value.filter(item => item !== rId)
  }

  const resolveRemarkInfoData = remark => {
    const resolveData = {
      // rid: generateRandomString(),
      ...syncObject(blankRemarkData, remark),
    }
    resolveData.body = syncObject(blankBodyData, resolveData.body)
    let images = []
    if (resolveData.body && resolveData.body.images) {
      images = resolveData.body.images.map(item => {
        const resolveImage = syncObject(blankImageData, item)
        return resolveImage
      })
    }
    resolveData.body.images = images

    let appends = []
    if (remark.appends) {
      appends = remark.appends.map(item => {
        const resolveAppends = syncObject(blankAppendData, item)
        return resolveAppends
      })
    }
    resolveData.appends = appends
    resolveData.user = syncObject(blankUserData, remark.user ? remark.user : {})
    return resolveData
  }

  const resolveRemarkListData = response => {
    const { remarks } = response.data.data
    const resolveData = remarks.reverse().map(item => resolveRemarkInfoData(item))
    return resolveData
  }

  const isSameDay = (N1, N2) => {
    const date1 = new Date(N1)
    const date2 = new Date(N2)
    const state = date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate()
    return state
  }

  return {
    isRemarkBusy,
    remarkText,
    remarkLists,
    randomKey,
    randomIdArray,
    blankAppendData,
    blankImageData,
    judgeTypeOption,

    resolveRemarkListData,
    resolveRemarkInfoData,
    generateRandomString,
    removeRandomIdArray,
    isSameDay,

    getCustomerRemarkList,
    getCustomerRemarkSearch,
    setCustomerRemarkCreate,
    setCustomerRemarkUpdate,
    setCustomerRemarkDelete,

    getRemarkAppendData,
    setRemarkAppendUpload,
    getRemarkAppendDownloadKey,
    // getRemarkAppendDownload,
  }
}

export const useUserModal = () => {
  const tableData = ref([])
  const isTableBusy = ref(true)

  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
  } = useTableComponent()

  const {
    useHttpCodeAlert,
  } = useAlert()

  tableColumns.value = [
    { label: '#編號', key: 'id' },
    { label: '使用者', key: 'name' },
    { label: '部門', key: 'department' },
    { label: '設定', key: 'use' },
    { label: '動作', key: 'actions' },
  ]

  const statusOptions = [{
    label: '停用', value: 0,
  }, {
    label: '啟用', value: 1,
  }]

  const setUserDelete = (...arg) => store.dispatch('admin-user-modal/setUserDelete', ...arg)
  const setUserCreate = (...arg) => store.dispatch('admin-user-modal/setUserCreate', ...arg)
  const setUserUpdate = (...arg) => store.dispatch('admin-user-modal/setUserUpdate', ...arg)

  const getUserListData = () => {
    isTableBusy.value = true
    store.dispatch('admin-user-modal/getUserList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      _: Date.now(),
    })
      .then(response => {
        const { data, total } = response.data.data
        tableData.value = data
        totalNum.value = total
        isTableBusy.value = false
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else getUserListData()
  }

  watch([currentPage, perPage, searchQuery], () => {
    getUserListData()
  })

  return {
    tableData,
    isTableBusy,
    statusOptions,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    refetchTable,

    setUserDelete,
    setUserCreate,
    setUserUpdate,
    getUserListData,
    useHttpCodeAlert,
  }
}

export const useCustomerLineChart = () => {
  const {
    adminBrandOptions,
  } = useCustomerSetting()

  const {
    searchAdminBrand,
  } = useCustomerList()

  const {
    useHttpCodeAlert,
  } = useAlert()

  const monthOptions = [
    { id: 1, name: '一月' },
    { id: 1, name: '二月' },
    { id: 1, name: '三月' },
    { id: 1, name: '四月' },
    { id: 1, name: '五月' },
    { id: 1, name: '六月' },
    { id: 1, name: '七月' },
    { id: 1, name: '八月' },
    { id: 1, name: '九月' },
    { id: 1, name: '十月' },
    { id: 1, name: '十一月' },
    { id: 1, name: '十二月' },
  ]

  const showChart = ref(false)
  const isBusy = ref(true)
  const isTableBusy = ref(false)
  const timeRange = ref(null)
  const chartData = ref(null)
  const monthChartData = ref(null)

  const themeColors = ['#65d8ff', '#826af9', '#ffbb53', '#e83e8c', '#37deb2', '#419fd9', '#f8d3ff',
    '#ff7f00', '#d2b0ff', '#ff0000', '#00ffff', '#b8c2cc', '#00ff7f', '#ff5b52', '#ffff00']

  const blankChartData1 = {
    options: {
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      colors: themeColors,
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        categories: [],
      },
    },
    series: [],
  }
  const formatDateRange = dateRange => {
    if (dateRange === null) {
      return ''
    }
    // 切分日期範圍
    const [startDate, endDate] = dateRange.split(' to ')

    // 解析開始日期
    const startDateObj = new Date(startDate)
    const startYear = startDateObj.getFullYear()
    const startMonth = String(startDateObj.getMonth() + 1).padStart(2, '0')

    // 解析結束日期
    const endDateObj = new Date(endDate)
    const endYear = endDateObj.getFullYear()
    const endMonth = String(endDateObj.getMonth() + 1).padStart(2, '0')

    // 返回格式化的日期範圍
    return {
      startTime: `${startYear}-${startMonth}`,
      endTime: `${endYear}-${endMonth}`,
    }
  }

  // (初始化)時間
  const today = new Date()
  const threeMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 3, 1)
  const endDate = new Date(today.getFullYear(), today.getMonth(), 0)
  timeRange.value = `${threeMonthsAgo} to ${endDate}`

  const getMetaListData = callback => {
    store.dispatch('api/getMetaList', { key: 'admin_brands' })
      .then(response => {
        const adminBrands = response.data.data.admin_brands
        adminBrandOptions.value = adminBrands
        callback()
      })
  }

  const getAdminBrandsLineChart = () => {
    const resolveTime = formatDateRange(timeRange.value)
    if (!resolveTime) return
    if (isTableBusy.value) return
    isTableBusy.value = true
    showChart.value = true
    store.dispatch('admin-customer/getLineChart', {
      admin_brand_id: searchAdminBrand.value,
      start_time: resolveTime.startTime,
      end_time: resolveTime.endTime,
    })
      .then(response => {
        const { dateData, totalData } = response.data.data
        const resolveChartData = JSON.parse(JSON.stringify(blankChartData1))
        resolveChartData.options.xaxis.categories = dateData // ['2024/01', '2024/02', '2024/03', '2024/04', '2024/05', '2024/06', '2024/07', '2024/08', '2024/09', '2024/10', '2024/11', '2024/12']

        const dataArray = []
        const keys = Object.keys(totalData)
        // const themeColors = generateColors(keys.length)
        for (let i = 0; i < keys.length; i += 1) {
          const key = keys[i]
          const value = totalData[key]
          dataArray.push({ adminBrandId: key, data: value })
        }

        const seriesData = dataArray.map(item => {
          const targetObject = adminBrandOptions.value.find(obj => obj.id === parseInt(item.adminBrandId, 10))
          const resolve = {
            name: targetObject ? targetObject.name : '未設定',
            data: item.data,
          }
          return resolve
        })
        resolveChartData.series = seriesData
        chartData.value = resolveChartData
        isTableBusy.value = false
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  return {
    showChart,
    isBusy,
    isTableBusy,
    chartData,
    monthChartData,

    searchAdminBrand,
    timeRange,
    monthOptions,
    adminBrandOptions,
    getMetaListData,
    getAdminBrandsLineChart,
  }
}
