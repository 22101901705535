<template>
  <div>
    Auth
  </div>
</template>

<script>
// import { watch } from 'vue'
// import router from '@/router'

export default {
  watch: {
    '$route.params.serial': 'refreshBranchData',
  },
  setup() {
    // const refreshBranchData = () => {
    //   const route = router.currentRoute
    //   const { serial } = route.params
    // }

    // return {
    //   refreshBranchData,
    // }
  },
}
</script>
